import React, { useState } from "react";
import TypeBar from "../components/TypeBar";
import { Container, Row, Col } from "react-bootstrap";
import ProductList from "../components/ProductList";
import Pages from "../components/Pages";
import DropdownSort from "../components/DropdownSort";
import {useDispatch} from "react-redux";
import {Helmet} from "react-helmet";
// import SliderList from "../components/UI/MySlider";


const Shop = () => {
    const [searchState, setSearch] = useState('');
    return(
        <Container>
            <Helmet>
                <meta name="description" content="Продукты Днепра Эстрея(Estrella) - мы продаем и доставляем продовольственные товары в Днепре по доступной цене." />
            </Helmet>
            {/* <SliderList/> */}
            <Row className="mt-2">
                <Col md={3}>
                    <TypeBar selectedType={'0'}/>
                </Col>
                <Col md={9}>
                    <hr />
                    <DropdownSort searchState={searchState} setSearch={setSearch} productsTypeName={'Все продукты'} />
                    <hr />
                    <ProductList searchState={searchState} productSelectedType={'0'}/>
                    <Pages />
                </Col>
            </Row>
        </Container>
    )
}

export default Shop;