import images from "./productsImages";
const defaultState = {
    page: 1,
    limit:8,
    totalPages: 0,
    sortedBy: 'byPopularity',
    products: [
        {
            name:'Минеральная вода "Поляна квасова" 1.5л.',
            img: images.polana,
            popularity:7,
            price:'35',
            pricePer: 'грн/шт.',
            id: '1',
            typeId: '1'
        },
        {name:'Минеральная вода "Утренняя роса" 0.5л.', img:images.rosa05,popularity:7,  price:'9.50',pricePer: 'грн/шт.',id: '2',typeId: '1'},
        {name:'Минеральная вода "Утренняя роса" 1.5л.', img:images.rosa,popularity:7,  price:'15',pricePer: 'грн/шт.',id: '3',typeId: '1'},
        {name:'Минеральная вода "Утренняя роса" 2л.', img:images.rosa15,popularity:7,  price:'16',pricePer: 'грн/шт.',id: '4',typeId: '1'},
        {name:'Сок 1.93л.', img:images.sok, price:'68',popularity:5, pricePer: 'грн/шт.',id: '5',typeId: '1'},
        {name:'Упаковка “Квас Тарас Черный”1.5л х 6 бутылок(цена 1 бутылки – 39 грн.)', img:images.kvasChorniy15, price:'234',popularity:7, pricePer: 'грн/шт.',id: '6',typeId: '1'},
        {name:'Упаковка “Квас Тарас Черный”0.5л х 6 банок(цена 1 банки – 25 грн.)', img:images.kvasChorniy05, price:'150',popularity:7, pricePer: 'грн/шт.',id: '7',typeId: '1'},
        {name:'Упаковка “Квас Тарас Белый”1.5л х 6 бутылок(цена 1 бутылки – 39 грн.)', img:images.kvasBeliy15, price:'234',popularity:7, pricePer: 'грн/шт.',id: '8',typeId: '1'},
        {name:'Упаковка “Квас Тарас Белый”0.5л х 6 банок(цена 1 банки – 25 грн.)', img:images.kvasBeliy05, price:'150',popularity:7, pricePer: 'грн/шт.',id: '9',typeId: '1'},
        {name:'Упаковка “Mirinda”0.33л х 6 банок(цена 1 банки – 17.50  грн.)', img:images.mirinda033, price:'105',popularity:7, pricePer: 'грн/шт.',id: '10',typeId: '1'},
        {name:'Упаковка “7UP”0.33л х 6 банок(цена 1 банки – 17.5  грн.)', img:images.sevenUp033, price:'105',popularity:7, pricePer: 'грн/шт.',id: '11',typeId: '1'},
        {name:'Упаковка “Pepsi”0.33л х 6 банок(цена 1 банки – 17.50 грн.)', img:images.pepsi033, price:'105',popularity:7, pricePer: 'грн/шт.',id: '12',typeId: '1'},
        {name:'Упаковка “Buvette №7”1.5л х 6 бутылок(цена 1 бутылки – 18 грн.)', img:images.buvette15, price:'108',popularity:7, pricePer: 'грн/шт.',id: '13',typeId: '1'},
        {name:'Упаковка “Borjomi”1.250л х 6 бутылок(цена 1 бутылки – 72.5 грн.)', img:images.borjomi1250, price:'435',popularity:7, pricePer: 'грн/шт.',id: '14',typeId: '1'},
        {name:'Упаковка “Borjomi”0.5л х 6 бутылок(цена 1 бутылки – 53.5 грн.)', img:images.borjomi05, price:'321',popularity:7, pricePer: 'грн/шт.',id: '15',typeId: '1'},
        {name:'Энергетик "Non Stop" Original 0.250мл.', img:images.nonStop0250, price:'20',popularity:7, pricePer: 'грн/шт.',id: '16',typeId: '1'},
        {
            name:'Грудка',
            img: images.grudka,
            popularity:5,
            price:'120',
            pricePer: 'грн/кг.',
            id: '1002',
            typeId: '2'
        },
        {name:'Голень',img:images.golen,popularity:5, price:'85', pricePer: 'грн/кг.',id: '1003',typeId: '2'},
        {name:'Желудок куринный',img:images.zheludoc,popularity:5,  price:'60',pricePer: 'грн/кг.',id: '1004',typeId: '2'},
        {name:'Колбаса куринная',img:images.kolbacaKyr,popularity:5, price:'160',pricePer: 'грн/кг.',id: '1006',typeId: '2'},
        {name:'Колбаса свинная',img:images.kolbacaSvin,popularity:5, price:'250',pricePer: 'грн/кг.',id: '1007',typeId: '2'},
        {name:'Крыло',img:images.krilo,popularity:5, price:'74',pricePer: 'грн/кг.',id: '1008',typeId: '2'},
        {name:'Куры бройлер',img:images.broiler,popularity:5, price:'82',pricePer: 'грн/кг.',id: '1009',typeId: '2'},
        {name:'Лапы куринные',img:images.lapki,popularity:5, price:'25',pricePer: 'грн/кг.',id: '1010',typeId: '2'},
        {name:'Мякоть свинная',img:images.makotSvin,popularity:5, price:'185',pricePer: 'грн/кг.',id: '1011',typeId: '2'},
        {name:'Ошеек свинной',img:images.osheek,popularity:5, price:'240',pricePer: 'грн/кг.',id: '1012',typeId: '2'},
        {name:'Печень куринная',img:images.pechenKyr,popularity:5, price:'90',pricePer: 'грн/кг.',id: '1013',typeId: '2'},
        {name:'Печень свинная',img:images.pechenSvin,popularity:5, price:'80',pricePer: 'грн/кг.',id: '1014',typeId: '2'},
        {name:'Ребро свинное',img:images.rebroSvin,popularity:5, price:'180',pricePer: 'грн/кг.',id: '1015',typeId: '2'},
        {name:'Сердце куриное',img:images.serceKyr,popularity:5, price:'90',pricePer: 'грн/кг.',id: '1016',typeId: '2'},
        {name:'Филе',img:images.fileKyr,popularity:5, price:'142',pricePer: 'грн/кг.',id: '1017',typeId: '2'},
        {name:'Филе бедра',img:images.fileBedKyr,popularity:5, price:'165',pricePer: 'грн/кг.',id: '1018',typeId: '2'},
        {name:'Филе с кожей',img:images.fileSKozh,popularity:5, price:'131',pricePer: 'грн/кг.',id: '1019',typeId: '2'},
        {name:'Четверть',img:images.chetvert,popularity:5, price:'76',pricePer: 'грн/кг.',id: '1020',typeId: '2'},
        {name:'Фарш куринный',img:images.farshKyr,popularity:5, price:'131',pricePer: 'грн/кг.',id: '1021',typeId: '2'},
        {name:'Колбаса вареная',img:images.kolbacaVar,popularity:5, price:'99',pricePer: 'грн/кг.',id: '1022',typeId: '2'},
        {name:'Сосиски высший сорт',img:images.sosiski,popularity:5, price:'145',pricePer: 'грн/кг.',id: '1023',typeId: '2'},
        {name:'Сосиски первый сорт',img:images.sosiskiPerviy,popularity:5, price:'125',pricePer: 'грн/кг.',id: '1024',typeId: '2'},
        {name:'Колбаса вареная(телятина)',img:images.klbacaTel,popularity:5, price:'224',pricePer: 'грн/кг.',id: '1025',typeId: '2'},
        {name:'Уши свиные (копченые) 100гр.',img:images.yshi,popularity:5, price:'24',pricePer: 'грн/шт.',id: '1026',typeId: '2'},
        {name:'Колбаса вареная "Столичная"',img:images.kolbacaVarStolichn,popularity:4, price:'230',pricePer: 'грн/кг.',id: '1027',typeId: '2'},
        {name:'Колбаса салями "Императорская"',img:images.imperatorskayaSalami,popularity:4, price:'240',pricePer: 'грн/кг.',id: '1028',typeId: '2'},
        {name:'Колбаса салями "Праздничная"',img:images.praznichnayaKolbas,popularity:4, price:'125',pricePer: 'грн/кг.',id: '1029',typeId: '2'},
        {name:'Колбаски Кабаносы',img:images.kolbsciKabanoci,popularity:4, price:'155',pricePer: 'грн/кг.',id: '1030',typeId: '2'},
        {name:'Грудинка свиная',img:images.grydinkaSvin,popularity:4, price:'280',pricePer: 'грн/кг.',id: '1031',typeId: '2'},
        {name:'Домашняя курица',img:images.homeChicken,popularity:2, price:'135',pricePer: 'грн/кг.',id: '1032',typeId: '2'},
        {
            name:'Йогурт 115гр "Фанни"',
            img:images.yogurtFanni,
            popularity:6,
            price:'9.5',
            pricePer: 'грн/шт.',
            id: '2001',
            typeId: '3'
        },
        {name:'Маргарин "Запорожский" сливочный 250гр.',img:images.margarin250,popularity:6, price:'29',pricePer: 'грн/шт.',id: '2002',typeId: '3'},
        {name:'Маргарин "Запорожский" сливочный 500гр.',img:images.margarin500,popularity:6, price:'57',pricePer: 'грн/шт.',id: '2003',typeId: '3'},
        {name:'Молоко "Волошкове поле" 2.5%',img:images.molokoVolosh,popularity:5, price:'30',pricePer: 'грн/шт.',id: '2004',typeId: '3'},
        {name:'Молоко "Lactel" ',img:images.molokoLactel,popularity:6, price:'32',pricePer: 'грн/шт.',id: '2005',typeId: '3'},
        {name:'Йогурт "Милкин"',img:images.yogurt,popularity:6, price:'28',pricePer: 'грн/шт.',id: '2006',typeId: '3'},
        {name:'Десерт "Милкин" ',img:images.decert,popularity:6, price:'34',pricePer: 'грн/шт.',id: '2007',typeId: '3'},
        {name:'Сливки "Белая Линия" ',img:images.slivki,popularity:6, price:'19',pricePer: 'грн/шт.',id: '2008',typeId: '3'},
        {name:'Молоко "Молочный визит" 2.5% 1000гр.',img:images.molokoMolochniyVizit, popularity:6,price:'31',pricePer: 'грн/шт.',id: '2009',typeId: '3'},
        {name:'Данон Активия',img:images.yogurtAktiviya, popularity:6, price:'32,10',pricePer: 'грн/шт.',id: '2010',typeId: '3'},
        {name:'Десерт Дольче 280гр.',img:images.dolceDecertStakan, popularity:6, price:'32,30',pricePer: 'грн/шт.',id: '2011',typeId: '3'},
        {name:'Дольче в бутылке 290гр.',img:images.yogurtDolceBut, popularity:6, price:'28,75',pricePer: 'грн/шт.',id: '2012',typeId: '3'},
        {name:'Маргарин молочный 500гр.',img:images.margarinMol500, popularity:6, price:'55.40',pricePer: 'грн/шт.',id: '2013',typeId: '3'},
        {name:'Маргарин сливочный "Олком" 450гр.',img:images.margarinOlkomSliv450, popularity:6, price:'60.40',pricePer: 'грн/шт.',id: '2014',typeId: '3'},
        {name:'Масло "Белоцерковское" 200гр.',img:images.masloBelicerkov, popularity:6, price:'73',pricePer: 'грн/шт.',id: '2015',typeId: '3'},
        {name:'Масло "Глечик" 73% 200гр.',img:images.masloGlechik, popularity:6, price:'132.50',pricePer: 'грн/шт.',id: '2016',typeId: '3'},
        {name:'Масло "Ферма" 73% 180гр.',img:images.masloFerma73, popularity:6, price:'67.50',pricePer: 'грн/шт.',id: '2017',typeId: '3'},
        {name:'Масло "Ферма" 82.5% 180гр.',img:images.masloFerma825, popularity:6, price:'91.25',pricePer: 'грн/шт.',id: '2018',typeId: '3'},
        {name:'Масло "Ферма" 400гр.',img:images.masloFerma73400, popularity:6, price:'144.50',pricePer: 'грн/шт.',id: '2019',typeId: '3'},
        {name:'Масло "Яготин" 82% 180гр.',img:images.masloYagotin, popularity:6, price:'84.50',pricePer: 'грн/шт.',id: '2020',typeId: '3'},
        {name:'Сыр "Канев" 50% твердый 160гр.',img:images.sirKaniv, popularity:5, price:'52',pricePer: 'грн/шт.',id: '2021',typeId: '3'},
        {name:'Сливки "Буренка" 10%  200гр.',img:images.slivkiBuronka, popularity:5, price:'21',pricePer: 'грн/шт.',id: '2022',typeId: '3'},
        {
            name:'Арнаутка 800гр.',
            img:images.arnautka,
            popularity:5,
            price:'22',
            pricePer: 'грн/шт.',
            id: '3001',
            typeId: '4'
        },
        {name:'Булгур "Сонечко" 500гр.',img:images.bulgur,popularity:5,price:'36',pricePer: 'грн/шт.',id: '3002',typeId: '4'},
        {name:'Булгур "Сонечко" с овощами 500гр.',img:images.bulgur,popularity:5,price:'42',pricePer: 'грн/шт.',id: '3003',typeId: '4'},
        {name:'Горох 800гр',img:images.goroh800,popularity:5,price:'16',pricePer: 'грн/шт.',id: '3004',typeId: '4'},
        {name:'Гречка 800гр. ',img:images.grechka,popularity:5,price:'45',pricePer: 'грн/шт.',id: '3005',typeId: '4'},
        {name:'Гречка не жаренная 1000гр.',img:images.grechka2,popularity:5,price:'65',pricePer: 'грн/шт.',id: '3006',typeId: '4'},
        {name:'Крупа кукурузная 800гр.',img:images.kukuruznayaKrupa,popularity:5,price:'16',pricePer: 'грн/шт.',id: '3007',typeId: '4'},
        {name:'Манка 800гр.',img:images.mannayaKrupa,popularity:5,price:'17',pricePer: 'грн/шт.',id: '3008',typeId: '4'},
        {name:'Перловка 800гр.',img:images.perlovka,popularity:5,price:'16',pricePer: 'грн/шт.',id: '3009',typeId: '4'},
        {name:'Пшеничка 5кг.',img:images.pshenichnayaKrupa,popularity:5,price:'55',pricePer: 'грн/шт.',id: '3010',typeId: '4'},
        {name: 'Пшеничная 800гр.', img: images.pshenichnayaKrupa,popularity:5, price: '9', pricePer: 'грн/шт.', id: '3011', typeId: '4'},
        {name: 'Пшено 800гр.', img: images.psheno,popularity:5, price: '17', pricePer: 'грн/шт.', id: '3012', typeId: '4'},
        {name: 'Рис длинный 800гр.', img: images.risDlinniy,popularity:5, price: '46', pricePer: 'грн/шт.', id: '3013', typeId: '4' },
        {name: 'Рис круглый 800гр.', img: images.risKrugliy,popularity:5, price: '45', pricePer: 'грн/шт.', id: '3014', typeId: '4'},
        {name: 'Рис пропаренный 800 гр.', img: images.risPropareniy,popularity:5, price: '48', pricePer: 'грн/шт.', id: '3015', typeId: '4'},
        {name: 'Рисовая сечка 800 гр.', img: images.risovayaSichka,popularity:5, price: '35', pricePer: 'грн/шт.', id: '3016', typeId: '4'},
        {name: 'Хлопья 350гр.', img: images.hlopya,popularity:5, price: '11', pricePer: 'грн/шт.', id: '3017', typeId: '4'}, 
        {name: 'Ячка 5кг.', img: images.yachka,popularity:5, price: '75', pricePer: 'грн/шт.', id: '3018', typeId: '4'}, 
        {name: 'Ячка 800гр.', img: images.yachka,popularity:5, price: '14', pricePer: 'грн/шт.', id: '3019', typeId: '4'}, 
        {name: 'Макароны Ярка ассорти 5кг.', img: images.yarka,popularity:5, price: '130', pricePer: 'грн/шт.', id: '3020', typeId: '4'},
        {name: 'Макароны Киев микс 1кг.', img: images.kievMics,popularity:5, price: '32', pricePer: 'грн/шт.', id: '3021', typeId: '4'},
        {name: 'Макароны Киев спагетти 1кг.', img: images.spagetti,popularity:5, price: '32', pricePer: 'грн/шт.', id: '3022', typeId: '4'},
        {name: 'Макароны Киев спагетти 450 гр.', img: images.spagetti450,popularity:5, price: '16', pricePer: 'грн/шт.', id: '3023', typeId: '4'},
        {name: 'Сахар 900гр.', img: images.sahar900,popularity:3, price: '28', pricePer: 'грн/шт.', id: '3024', typeId: '4'},
        {name: 'Сахар 5кг.', img: images.sahar,popularity:3, price: '170', pricePer: 'грн/шт.', id: '3025', typeId: '4'},
        {name: 'Соль 1кг.', img: images.sol,popularity:3, price: '17', pricePer: 'грн/шт.', id: '3026', typeId: '4'},
        {name: 'Соль 5кг.', img: images.sol,popularity:3, price: '85', pricePer: 'грн/шт.', id: '3027', typeId: '4'},
        {name: 'Овсянные хлопья 350гр.', img: images.hlopya,popularity:5, price: '12', pricePer: 'грн/шт.', id: '3028', typeId: '4'},
        {name: 'Мука "Днипро млин" 2кг.', img: images.muka,popularity:5, price: '32', pricePer: 'грн/шт.', id: '3029', typeId: '4'},
        {name: 'Мука "Днипро млин" 5кг.', img: images.muka5Kg,popularity:5, price: '75', pricePer: 'грн/шт.', id: '3030', typeId: '4'},
        {name: 'Мука "Сонечко" 5кг.', img: images.mukaSon,popularity:5, price: '75', pricePer: 'грн/шт.', id: '3031', typeId: '4'},
        {name: 'Хлопья "Брюгтен"', img: images.bruggenHlopya,popularity:5, price: '47.50', pricePer: 'грн/шт.', id: '3032', typeId: '4'},
        {name: 'Макароны "Ярка" 1кг.', img: images.yarka1,popularity:5, price: '30', pricePer: 'грн/шт.', id: '3033', typeId: '4'},
        {name: 'Овсянка', img: images.ovsanka, popularity:4, price: '30', pricePer: 'грн/кг.', id: '3034', typeId: '4'},
        {name: 'Сахар', img: images.sahar1000, popularity:2, price: '28', pricePer: 'грн/кг.', id: '3035', typeId: '4'},
        {
            name:'Апельсин',
            img:images.apelsin,
            popularity:3,
            price:'49',
            pricePer: 'грн/кг.',
            id: '4001',
            typeId: '5'
        },
        {name:'Свекла',img:images.buryak,popularity:1,price:'35',pricePer: 'грн/кг.',id: '4002',typeId: '5'},
        // {name:'Банан(ящик 18 кг.)Banana Best',img:images.banani,price:'1300',pricePer: 'грн/шт.',id: '4003',typeId: '5'},
        {name:'Банан(ящик 18 кг.) Imperial',img:images.banani,popularity:4,price:'880',pricePer: 'грн/шт.',id: '4004',typeId: '5'},
        {name:'Грибы шампиньены',img:images.gribiPecher,popularity:2, price:'86',pricePer: 'грн/кг.',id: '4005',typeId: '5'},
        {name:'Капуста',img:images.kapusta,popularity:1,price:'12',pricePer: 'грн/кг.',id: '4006',typeId: '5'},
        {name:'Капуста пекинская',img:images.kapustaPecin,popularity:3,price:'35',pricePer: 'грн/кг.',id: '4007',typeId: '5'},
        {name:'Капуста краснокочанная',img:images.kapustaKrasnokoch,popularity:3,price:'44',pricePer: 'грн/кг.',id: '4008',typeId: '5'},
        {name:'Капуста цветная',img:images.kapustaCvetnaya,popularity:3,price:'90',pricePer: 'грн/кг.',id: '409',typeId: '5'},
        {name:'Картофель Гранада',img:images.kartofGranada,popularity:2,price:'26',pricePer: 'грн/кг.',id: '4010',typeId: '5'},
        {name:'Картофель Бела Роса',img:images.kartofBelarosa,popularity:2,price:'24',pricePer: 'грн/кг.',id: '4011',typeId: '5'},
        // {name:'Картофель молодой',img:images.kartofArizona,popularity:2,price:'17',pricePer: 'грн/кг.',id: '4012',typeId: '5'},
        {name:'Лимон',img:images.limon,popularity:3,price:'69',pricePer: 'грн/кг.',id: '4013',typeId: '5'},
        {name:'Лук',img:images.luk,popularity:1,price:'22',pricePer: 'грн/кг.',id: '4014',typeId: '5'},
        // {name:'Лук крупный',img:images.lukKrupniy,price:'49',pricePer: 'грн/кг.',id: '4015',typeId: '5'},
        {name:'Мандарин',img:images.mandarini,popularity:3,price:'65',pricePer: 'грн/кг.',id: '4016',typeId: '5'},
        {name:'Морковь',img:images.morkov,popularity:1,price:'21',pricePer: 'грн/кг.',id: '4017',typeId: '5'},
        {name:'Огурец',img:images.ogurci,popularity:1, price:'46',pricePer: 'грн/кг.',id: '4018',typeId: '5'},
        {name:'Перец красный',img:images.krasniyPerec,popularity:2,price:'140',pricePer: 'грн/кг.',id: '4019',typeId: '5'},
        // {name:'Перец оранжевый',img:images.perecOranzh,popularity:3,price:'110',pricePer: 'грн/кг.',id: '4020',typeId: '5'},
        {name:'Помидор красный',img:images.pomidor,popularity:1,price:'68',pricePer: 'грн/кг.',id: '4021',typeId: '5'},
        {name:'Фасоль',img:images.fasol,popularity:4,price:'115',pricePer: 'грн/кг.',id: '4022',typeId: '5'},
        {name:'Чеснок',img:images.garlic,popularity:2,price:'159',pricePer: 'грн/кг.',id: '4023',typeId: '5'},
        {name:'Яблоко Гала',img:images.yablGala,popularity:2,price:'19.5',pricePer: 'грн/кг.',id: '4024',typeId: '5'},
        {name:'Яблоко Голден',img:images.yablGold,popularity:3,price:'21',pricePer: 'грн/кг.',id: '4025',typeId: '5'},
        {name:'Яблоко Ред Чиф',img:images.yablRedChif,popularity:3,price:'28',pricePer: 'грн/кг.',id: '4026',typeId: '5'},
        {name:'Гранат',img:images.granat,popularity:4,price:'158',pricePer: 'грн/кг.',id: '4027',typeId: '5'},
        {name:'Цукини',img:images.cykini,popularity:4,price:'78',pricePer: 'грн/кг.',id: '4028',typeId: '5'},
        {name:'Вишня',img:images.vishna,popularity:4,price:'90',pricePer: 'грн/кг.',id: '4029',typeId: '5'},
        {name:'Нектарин',img:images.nektarin,popularity:3,price:'105',pricePer: 'грн/кг.',id: '4030',typeId: '5'},
        // {name:'Малина',img:images.malina,popularity:3,price:'92',pricePer: 'грн/кг.',id: '4031',typeId: '5'},
        {name:'Имбирь',img:images.imbir,popularity:4,price:'185',pricePer: 'грн/кг.',id: '4032',typeId: '5'},
        {name:'Грейпфрут',img:images.greypfrut,popularity:4,price:'49',pricePer: 'грн/кг.',id: '4033',typeId: '5'},
        {name:'Помидоры "Черри"',img:images.cherri,popularity:3,price:'88',pricePer: 'грн/кг.',id: '4034',typeId: '5'},
        {name:'Ананас',img:images.ananas,popularity:3,price:'135',pricePer: 'грн/шт.',id: '4035',typeId: '5'},
        // {name:'Манго',img:images.mango,popularity:3,price:'52',pricePer: 'грн/кг.',id: '4036',typeId: '5'},
        {name:'Авокадо',img:images.avokado,popularity:4,price:'48',pricePer: 'грн/шт.',id: '4037',typeId: '5'},
        {name:'Груша',img:images.grushi,popularity:4,price:'125',pricePer: 'грн/кг.',id: '4038',typeId: '5'},
        {name:'Абрикос',img:images.abricos,popularity:3,price:'86',pricePer: 'грн/кг.',id: '4039',typeId: '5'},
        {name:'Персик инжирный',img:images.persikInzir,popularity:4,price:'87',pricePer: 'грн/кг.',id: '4040',typeId: '5'},
        {name:'Арбуз',img:images.arbuz,popularity:3,price:'33',pricePer: 'грн/кг.',id: '4041',typeId: '5'},
        {name:'Персик',img:images.persik,popularity:4,price:'80',pricePer: 'грн/кг.',id: '4042',typeId: '5'},
        // {name:'Редис',img:images.redis,popularity:4,price:'41',pricePer: 'грн/кг.',id: '4043',typeId: '5'},
        {name:'Лайм',img:images.laim,popularity:4,price:'160',pricePer: 'грн/кг.',id: '4044',typeId: '5'},
        {name:'Дыня',img:images.dina,popularity:4,price:'68',pricePer: 'грн/кг.',id: '4045',typeId: '5'},
        {name:'Авокадо "Хаас"',img:images.avokXaac,popularity:4,price:'70',pricePer: 'грн/шт.',id: '4046',typeId: '5'},
        {name:'Перец жёлтый',img:images.perecZhelt,popularity:4,price:'135',pricePer: 'грн/кг.',id: '4047',typeId: '5'},
        // {name:'Сливы',img:images.slivi,popularity:4,price:'72',pricePer: 'грн/кг.',id: '4048',typeId: '5'},
        {name:'Киви',img:images.kivi,popularity:4,price:'155',pricePer: 'грн/кг.',id: '4049',typeId: '5'},
        {name:'Кабачки',img:images.kabachki,popularity:2,price:'35',pricePer: 'грн/кг.',id: '4050',typeId: '5'},
        {name:'Баклажаны',img:images.baklazhani,popularity:2,price:'140',pricePer: 'грн/кг.',id: '4051',typeId: '5'},
        {name:'Клубника',img:images.klubnika,popularity:4,price:'85',pricePer: 'грн/кг.',id: '4052',typeId: '5'},
        {name:'Виноград "Кишмиш"',img:images.vinograd,popularity:4,price:'135',pricePer: 'грн/кг.',id: '4053',typeId: '5'},
        {name:'Лук Марс',img:images.lukMars,popularity:4,price:'32',pricePer: 'грн/кг.',id: '4054',typeId: '5'},
        {name:'Батат',img:images.batat,popularity:4,price:'120',pricePer: 'грн/кг.',id: '4055',typeId: '5'},
        {name:'Дайкон',img:images.daikon,popularity:4,price:'55',pricePer: 'грн/кг.',id: '4056',typeId: '5'},
        {name:'Капуста Брокколи',img:images.kapusBrokk,popularity:4,price:'100',pricePer: 'грн/кг.',id: '4057',typeId: '5'},
        {name:'Ежевика',img:images.eshevika,popularity:4,price:'90',pricePer: 'грн/кг.',id: '4058',typeId: '5'},
        {name:'Помидор коктейльный',img:images.pomidorKokt,popularity:2,price:'85',pricePer: 'грн/кг.',id: '4059',typeId: '5'},
        // {name:'Смородина черная',img:images.smorodina,popularity:4,price:'75',pricePer: 'грн/кг.',id: '4060',typeId: '5'},
        // {name:'Голубика',img:images.golubica,popularity:4,price:'145',pricePer: 'грн/кг.',id: '4061',typeId: '5'},
        {name:'Перец Белозерка',img:images.perecBeloz,popularity:2,price:'30',pricePer: 'грн/кг.',id: '4062',typeId: '5'},
        // {name:'Огурец корнишон',img:images.ogurecKornishon,popularity:2,price:'70',pricePer: 'грн/кг.',id: '4063',typeId: '5'},
        {name:'Помидор сливка',img:images.pomidorSlivka,popularity:2,price:'100',pricePer: 'грн/кг.',id: '4064',typeId: '5'},
        {name:'Перец Капия',img:images.perecKapia,popularity:2,price:'145',pricePer: 'грн/кг.',id: '4065',typeId: '5'},
        {name:'Перец Чили зеленый',img:images.chiliZelen,popularity:2,price:'80',pricePer: 'грн/кг.',id: '4066',typeId: '5'},
        // {name:'Перец Чили красный',img:images.chiliKrasn,popularity:2,price:'120',pricePer: 'грн/кг.',id: '4067',typeId: '5'},
        {name:'Картофель "Ривьера"',img:images.riviera,popularity:1,price:'23',pricePer: 'грн/кг.',id: '4068',typeId: '5'},
        {name:'Лук "Стерлинг"',img:images.lukSterling,popularity:3,price:'22',pricePer: 'грн/кг.',id: '4069',typeId: '5'},
        // {name:'Помидор полевой',img:images.pomidorPole,popularity:1,price:'35',pricePer: 'грн/кг.',id: '4070',typeId: '5'},
        {name:'Лук-порей',img:images.lukPorey,popularity:4,price:'92',pricePer: 'грн/кг.',id: '4071',typeId: '5'},
        {name:'Корень сельдерея',img:images.korenSelder,popularity:4,price:'65',pricePer: 'грн/кг.',id: '4072',typeId: '5'},
        {name:'Огурец гладкий',img:images.ogurGlad,popularity:2,price:'55',pricePer: 'грн/кг.',id: '4073',typeId: '5'},
        // {name:'Чеснок',img:images.garlic,popularity:2,price:'155',pricePer: 'грн/кг.',id: '4074',typeId: '5'},
        {name:'Капуста молодая',img:images.cabbageEarl,popularity:2,price:'10',pricePer: 'грн/кг.',id: '4075',typeId: '5'},
        {
            name:'Густера',
            img:images.gystera,
            popularity:6,
            price:'200',
            pricePer: 'грн/кг.',
            id: '5001',
            typeId: '6'
        },
        {name:'Мойва холодного копчения',img:images.moyva, popularity:6, price:'150',pricePer: 'грн/кг.',id: '5002',typeId: '6'},
        {name:'Салака горячего копчения',img:images.salaka,popularity:6, price:'145',pricePer: 'грн/кг.',id: '5003',typeId: '6'},
        {name:'Шпроты с икрой',img:images.shprot, popularity:6, price:'135',pricePer: 'грн/кг.',id: '5004',typeId: '6'},
        {name:'Обрезь лосося 1кг. 300гр.',img:images.obrLosos,popularity:6, price:'370',pricePer: 'грн/шт.',id: '5005',typeId: '6'},
        {name:'Вомер',img:images.vomer, popularity:6, price:'395',pricePer: 'грн/кг.',id: '5006',typeId: '6'},
        {name:'Брюшки лосося',img:images.bryshkiLos, popularity:6, price:'270',pricePer: 'грн/кг.',id: '5007',typeId: '6'},
        {name:'Морепродукты',img:images.moreproduc, popularity:6, price:'70',pricePer: 'грн/шт.',id: '5008',typeId: '6'},
        {name:'Лососевое масло 200гр.',img:images.lososevoeMaslo, popularity:6, price:'70',pricePer: 'грн/шт.',id: '5009',typeId: '6'},
        {name:'Лососевый набор 500гр.',img:images.lososevoyNabor, popularity:6, price:'120',pricePer: 'грн/шт.',id: '5010',typeId: '6'},
        {name:'Лосось в масле 180гр.',img:images.lososVMasle, popularity:6, price:'71',pricePer: 'грн/шт.',id: '5011',typeId: '6'},
        {name:'Мясо мидий 500гр.',img:images.myasoMidiy, popularity:6, price:'120',pricePer: 'грн/шт.',id: '5012',typeId: '6'},
        {name:'Сельдь в масле 180гр.',img:images.seldVMasle, popularity:6, price:'36',pricePer: 'грн/шт.',id: '5013',typeId: '6'},
        {name:'Хек Атлантический ящик(10кг)',img:images.hek, popularity:5, price:'1270',pricePer: 'грн/шт.',id: '5014',typeId: '6'},
        
        {
            name:'Салат Айсберг',
            img:images.aisberg,
            popularity:4,
            price:'85',
            pricePer: 'грн/шт.',
            id: '6001',
            typeId: '7'
        },
        {name:'Салат Лолло Бионда',img:images.bionda,popularity:4,price:'230',pricePer: 'грн/кг.',id: '6002',typeId: '7'},
        // {name:'Салат Лолло Росса',img:images.rossa,popularity:4,price:'165',pricePer: 'грн/кг.',id: '6003',typeId: '7'},
        {name:'Кинза',img:images.kinza,popularity:4,price:'150',pricePer: 'грн/кг.',id: '6004',typeId: '7'},
        {name:'Лук зеленый',img:images.lykZel,popularity:4, price:'170',pricePer: 'грн/кг.',id: '6005',typeId: '7'},
        {name:'Мята',img:images.myata,popularity:4, price:'300',pricePer: 'грн/кг.',id: '6006',typeId: '7'},
        {name:'Петрушка',img:images.petryshka,popularity:4, price:'200',pricePer: 'грн/кг.',id: '6007',typeId: '7'},
        {name:'Салат Ромен',img:images.romen,popularity:4, price:'195',pricePer: 'грн/кг.',id: '6008',typeId: '7'},
        {name:'Укроп',img:images.ykrop,popularity:4, price:'200',pricePer: 'грн/кг.',id: '6009',typeId: '7'},
        {name:'Хрен',img:images.hren,popularity:4, price:'180',pricePer: 'грн/кг.',id: '6010',typeId: '7'},
        {name:'Хрен для консервации. 1 пучок',img:images.hrenDlaKons,popularity:4, price:'30',pricePer: 'грн/шт.',id: '6011',typeId: '7'},
        {name:'Красный базилик',img:images.bazilicKrasn,popularity:4, price:'330',pricePer: 'грн/кг.',id: '6012',typeId: '7'},
        {name:'Шпинат',img:images.shpinat,popularity:4, price:'240',pricePer: 'грн/кг.',id: '6013',typeId: '7'},
        {name:'Рукола',img:images.rykola, popularity:4, price:'180',pricePer: 'грн/кг.',id: '6014',typeId: '7'},
        {name:'Тархун',img:images.tarhun, popularity:4, price:'230',pricePer: 'грн/кг.',id: '6015',typeId: '7'},
        {name:'Стебель сельдерея',img:images.selderejStebel, popularity:4, price:'110',pricePer: 'грн/кг.',id: '6016',typeId: '7'},
        {
            name:'Кофе "Nescafe" 3в1. 1 упаковка(20 стиков)',
            img:images.nescTriVOdnom,
            popularity:7,
            price:'120',
            pricePer: 'грн/шт.',
            id: '7001',
            typeId: '8'
        },
        {name:'Кофе "Nescafe" Gold (25 стиков)',img:images.nsGold, popularity:7,price:'140',pricePer: 'грн/шт.',id: '7002',typeId: '8'},
        {name:'Кофе "Nescafe" Expresso (25 стиков)',img:images.nsExp, popularity:7,price:'140',pricePer: 'грн/шт.',id: '7003',typeId: '8'},
        {name:'Кофе "Nescafe" Classic (25 стиков)',img:images.nescafeCl, popularity:7,price:'140',pricePer: 'грн/шт.',id: '7004',typeId: '8'},
        {name:'Marshmellow "Їж наше"',img:images.marshmBarb, popularity:7, price:'80',pricePer: 'грн/шт.',id: '7005',typeId: '8'},
        {name:'Marshmellows "Cornelis"',img:images.marshmCorn, popularity:7, price:'78',pricePer: 'грн/шт.',id: '7006',typeId: '8'},
        {name:'Шоколад "Studentska" ',img:images.shokolStuden, popularity:7, price:'60',pricePer: 'грн/шт.',id: '7007',typeId: '8'},
        {name:'Шоколад "Schogetten" Black & White',img:images.shokolSCBlack, popularity:7, price:'50',pricePer: 'грн/шт.',id: '7008',typeId: '8'},
        // {name:'Шоколадка "Schogetten" Latte Macchiato',img:images.shokolSCLate, price:'50',pricePer: 'грн/шт.',id: '7009',typeId: '8'},
        {name:'Сгущенное молоко "Ириска" 370гр.',img:images.sgushenka, popularity:7, price:'57',pricePer: 'грн/шт.',id: '7010',typeId: '8'},
        {name:'Чай "Мономах" Earl Grey 40 пакетиков',img:images.monomaxEarlGrey24, popularity:7, price:'43',pricePer: 'грн/шт.',id: '7011',typeId: '8'},
        {name:'Чай "Lovare" 1001 Nights 24 пакетика' ,img:images.lovare1001N24, popularity:7, price:'48',pricePer: 'грн/шт.',id: '7012',typeId: '8'},
        {name:'Чай "Lovare" Champagne Splashes 24 пакетика',img:images.lovarechampag24, popularity:7, price:'48',pricePer: 'грн/шт.',id: '7013',typeId: '8'},
        {name:'Чай "Lovare" Champagne Splashes 80гр.',img:images.loverechampag80gr, popularity:7, price:'66',pricePer: 'грн/шт.',id: '7014',typeId: '8'},
        {name:'Кофе растворимый "Cart Noire" 140гр.',img:images.cartNoire140, popularity:7, price:'296',pricePer: 'грн/шт.',id: '7015',typeId: '8'},
        {name:'Кофе растворимый "Jacobs" Monarch 120гр.',img:images.jacobs120, popularity:7, price:'172',pricePer: 'грн/шт.',id: '7016',typeId: '8'},
        {name:'Кофе растворимый "Jacobs" Monarch 200гр.',img:images.jacobs200, popularity:7, price:'270',pricePer: 'грн/шт.',id: '7017',typeId: '8'},
        {name:'Шоколад молочный "Milka" Oreo 300гр.',img:images.milkaOreo300, popularity:7, price:'125',pricePer: 'грн/шт.',id: '7018',typeId: '8'},
        {name:'Шоколад молочный "Milka" Oreo 100гр.',img:images.milkaOreo100, popularity:7, price:'45',pricePer: 'грн/шт.',id: '7019',typeId: '8'},
        {name:'Шоколад "Milka" Альпийское молоко 270гр.',img:images.milkaAlpine270, popularity:7, price:'125',pricePer: 'грн/шт.',id: '7020',typeId: '8'},
        {name:'Шоколад "Ritter Sport" с лесными орехами 100гр.',img:images.ritterSportNuts, popularity:7, price:'65',pricePer: 'грн/шт.',id: '7021',typeId: '8'},
        {name:'Шоколад "Ritter Sport" с печеньем 100гр.',img:images.ritterSportBiscuit, popularity:7, price:'65',pricePer: 'грн/шт.',id: '7022',typeId: '8'},
        {name:'Шоколад "Ritter Sport" с какао и вафлями 100гр.',img:images.ritterSportCacao, popularity:7, price:'65',pricePer: 'грн/шт.',id: '7023',typeId: '8'},
        {name:'Кофе зерно "Ricco Coffee" 1кг.',img:images.riccoCoffe, popularity:7, price:'439',pricePer: 'грн/шт.',id: '7024',typeId: '8'},
        {name:'Леденцы "Woogie" 200 гр. со вкусом малины',img:images.woogieMalina, popularity:7, price:'80',pricePer: 'грн/шт.',id: '7025',typeId: '8'},
        {name:'Леденцы "Woogie" 200 гр. со вкусом лимона и апельсина',img:images.woogieLimonApels, popularity:7, price:'80',pricePer: 'грн/шт.',id: '7026',typeId: '8'},
        {name:'Леденцы "Woogie" 200 гр. со вкусом яблока',img:images.woogieYabl, popularity:7, price:'80',pricePer: 'грн/шт.',id: '7027',typeId: '8'},
        {name:'Леденцы "Woogie" 200 гр. со вкусом вишни',img:images.woogieVish, popularity:7, price:'80',pricePer: 'грн/шт.',id: '7028',typeId: '8'},
        {name:'Леденцы "Woogie" 200 гр. со вкусом апельсина',img:images.woogieApels, popularity:7, price:'80',pricePer: 'грн/шт.',id: '7029',typeId: '8'},
        {name:'Леденцы "Woogie" 200 гр. со вкусом лесных ягод',img:images.woogieYagodi, popularity:7, price:'80',pricePer: 'грн/шт.',id: '7030',typeId: '8'},
        {name:'Жевательные конфеты "Toffix" 1кг.',img:images.toffix, popularity:7, price:'220',pricePer: 'грн/шт.',id: '7031',typeId: '8'},
        {name:'Жевательные кислые  конфеты "Toffix" 1кг.',img:images.toffixSour, popularity:7, price:'220',pricePer: 'грн/шт.',id: '7032',typeId: '8'},
        {name:'Конфеты "Amanti " 1кг. "Вишня"',img:images.amantiVishnya, popularity:7, price:'270',pricePer: 'грн/шт.',id: '7033',typeId: '8'},
        {name:'Конфеты "Amanti " 1кг. "Кокос Баунти"',img:images.amantiBaunty, popularity:7, price:'270',pricePer: 'грн/шт.',id: '7034',typeId: '8'},
        {name:'Конфеты "Amanti " 1кг. "Манго"',img:images.amantiMango, popularity:7, price:'270',pricePer: 'грн/шт.',id: '7035',typeId: '8'},
        {name:'Конфеты "Amanti " 1кг. "Мохито"',img:images.amantiMohito, popularity:7, price:'270',pricePer: 'грн/шт.',id: '7036',typeId: '8'},
        {name:'Конфеты "Amanti " 1кг. "Пина Колада"',img:images.amantiKolada, popularity:7, price:'270',pricePer: 'грн/шт.',id: '7037',typeId: '8'},
        {name:'Мед разнотравье 1л.',img:images.med, popularity:5, price:'145',pricePer: 'грн/шт.',id: '7038',typeId: '8'},
        {name:'Растворимый кофе 500гр.',img:images.rastvorimiyKofe, popularity:5, price:'320',pricePer: 'грн/шт.',id: '7039',typeId: '8'},
        {name:'Чай "Kolonist" Mixed Fruits 100гр.',img:images.chaiKilonistFruits, popularity:5, price:'115',pricePer: 'грн/шт.',id: '7040',typeId: '8'},
        {name:'Чай "Kolonist" Cranberry Mango 100гр.',img:images.chaiKilonistCranberry, popularity:5, price:'115',pricePer: 'грн/шт.',id: '7041',typeId: '8'},
        {name:'Кофе зерновой "Kolonist" Альпийский шоколад',img:images.cofeKilonistZerno, popularity:5, price:'135',pricePer: 'грн/шт.',id: '7042',typeId: '8'},
        {name:'Чай "Kolonist" Pekoe 200гр.',img:images.chaiKilonistPekoe, popularity:5, price:'190',pricePer: 'грн/шт.',id: '7043',typeId: '8'},
        {name:'Коллекция чая "Lovare" Fest Tea Set 90 пакетиков',img:images.lovareFestSet, popularity:5, price:'175',pricePer: 'грн/шт.',id: '7044',typeId: '8'},
        {name:'Коллекция чая "Lovare" Prime Tea Set 90 пакетиков',img:images.lovarePrimeSet, popularity:5, price:'175',pricePer: 'грн/шт.',id: '7045',typeId: '8'},
        {name:'Чай "Мономах" Summer Tea 40 пакетиков',img:images.monomahSummerTeaSmall, popularity:5, price:'44',pricePer: 'грн/шт.',id: '7046',typeId: '8'},
        {name:'Чай "Qualitea" Манго-мальва 100гр.',img:images.qualiteaMango, popularity:5, price:'90',pricePer: 'грн/шт.',id: '7047',typeId: '8'},
        {
            name:'Уксус',
            img:images.yksus,
            popularity:5,
            price:'17.5',
            pricePer: 'грн/шт.',
            id: '8001',
            typeId: '9'
        },
        {name:'Подсолнечное масло 5л.',img:images.maslo5, popularity:1,price:'230',pricePer: 'грн/шт.',id: '8002',typeId: '9'},
        {name:'Подсолнечное масло 1л.',img:images.maslo1, popularity:2,price:'47',pricePer: 'грн/шт.',id: '8003',typeId: '9'},
        {name:'Подсолнечное масло "Денко" 1л.',img:images.masloDenko, popularity:2,price:'58',pricePer: 'грн/шт.',id: '8004',typeId: '9'},
        {name:'Подсолнечное масло "Ольвия" 1л.',img:images.masloOlivia, popularity:2,price:'59',pricePer: 'грн/шт.',id: '8005',typeId: '9'},
        {
            name:'Яйцо с 2 желтками',
            img:images.yaytso2,
            popularity:2,
            price:'7.6',
            pricePer: 'грн/шт.',
            id: '9001',
            typeId: '10'
        },
        {name:'Яйцо XL',img:images.yaytsoXL, popularity:2, price:'7.2',pricePer: 'грн/шт.',id: '9002',typeId: '10'},
        {name:'Яйцо C0',img:images.yaytsoC0, popularity:1, price:'5.9',pricePer: 'грн/шт.',id: '9003',typeId: '10'},
        {name:'Яйцо C1',img:images.yaytsoC1, popularity:3, price:'5.5',pricePer: 'грн/шт.',id: '9004',typeId: '10'},
        {
            name:'Петрушка сушеная 10гр.',
            img:images.petrushka,
            popularity:3,
            price:'7.6',
            pricePer: 'грн/шт.',
            id: '10001',
            typeId: '11'
        },
        {name:'Базилик сушеный 10гр.',img:images.bazilik, popularity:3, price:'6.4',pricePer: 'грн/шт.',id: '10002',typeId: '11'},
        {name:'Укроп сушеный 10гр.',img:images.ykropSysh, popularity:3, price:'6.6',pricePer: 'грн/шт.',id: '10003',typeId: '11'},
        {name:'Горчица зерно сухая',img:images.gorchitsaZerno, popularity:3, price:'3.75',pricePer: 'грн/шт.',id: '10004',typeId: '11'},
        {name:'Горчичный порошек',img:images.gorchicaPorosh, popularity:3, price:'10.60',pricePer: 'грн/шт.',id: '10005',typeId: '11'},
        {name:'Приправа "Торчин" 10 овощей  60гр.',img:images.torchin10Ovoshei, popularity:3, price:'17',pricePer: 'грн/шт.',id: '10006',typeId: '11'},
        {
            name:'Ананас кольцами ж/б',
            img:images.ananasKolca,
            popularity:3,
            price:'76.25',
            pricePer: 'грн/шт.',
            id: '11001',
            typeId: '12'
        },
        {name:'Ананасовые кусочки 585гр. ж/б',img:images.ananacKusochki, popularity:3, price:'67.50',pricePer: 'грн/шт.',id: '11002',typeId: '12'},
        {name:'Горошек "АСП"',img:images.gorohASP, popularity:3, price:'39.60',pricePer: 'грн/шт.',id: '11003',typeId: '12'},
        {name:'Горошек "Бабусин продукт"',img:images.gorohBabush, popularity:3, price:'36.25',pricePer: 'грн/шт.',id: '11004',typeId: '12'},
        {name:'Горошек "Верес"',img:images.gorohVeres, popularity:3, price:'41.25',pricePer: 'грн/шт.',id: '11005',typeId: '12'},
        {name:'Горошек "Фортуна"',img:images.gorohFortuna, popularity:3, price:'23.75',pricePer: 'грн/шт.',id: '11006',typeId: '12'},
        {name:'Горошек "Щедра Нива"',img:images.gorohShedraNyva, popularity:3, price:'31.25',pricePer: 'грн/шт.',id: '11007',typeId: '12'},
        {name:'Печеночный паштет',img:images.pashtetPechon, popularity:3, price:'28',pricePer: 'грн/шт.',id: '11008',typeId: '12'},
        {name:'Паштет "Домашний"',img:images.pashtetDomashn, popularity:3, price:'28',pricePer: 'грн/шт.',id: '11009',typeId: '12'},
        {name:'Паштет "Британский"',img:images.pashtetBrit, popularity:3, price:'28',pricePer: 'грн/шт.',id: '11010',typeId: '12'},
        {name:'Паштет "Мясной"',img:images.PashtetMasnoy, popularity:3, price:'28',pricePer: 'грн/шт.',id: '11011',typeId: '12'},
        {name:'Паштет с черносливом',img:images.pashtetSChernosliv, popularity:3, price:'28',pricePer: 'грн/шт.',id: '11012',typeId: '12'},
        {name:'Кукуруза консервированная "Бабусин продукт"',img:images.kukuruzaKonservirovannayaBabusin, popularity:3, price:'36.25',pricePer: 'грн/шт.',id: '11013',typeId: '12'},
        {name:'Кукуруза консервированная "Верес"',img:images.kukurudzaVeres, popularity:3, price:'41.25',pricePer: 'грн/шт.',id: '11014',typeId: '12'},
        {name:'Бычки Баринец в томате',img:images.bichkiVTomate, popularity:3, price:'43.75',pricePer: 'грн/шт.',id: '11015',typeId: '12'},
        {name:'Грибы ассорти',img:images.gribiAsorti, popularity:3, price:'52.50',pricePer: 'грн/шт.',id: '11016',typeId: '12'},
        {name:'Грибы опята 500гр.',img:images.gribiOpyata, popularity:3, price:'58.75',pricePer: 'грн/шт.',id: '11017',typeId: '12'},
        {name:'Грибы Шампиньоны 340гр.',img:images.marinShamp, popularity:3, price:'56,25',pricePer: 'грн/шт.',id: '11018',typeId: '12'},
        {name:'Грибы Шампиньоны 500гр.',img:images.marinShamp2, popularity:3, price:'78,75',pricePer: 'грн/шт.',id: '11019',typeId: '12'},
        {name:'Грибы Шампиньоны 720гр.',img:images.marinShamp3, popularity:3, price:'96,25',pricePer: 'грн/шт.',id: '11020',typeId: '12'},
        {name:'Маслины "Иберика" 420гр.',img:images.ibericaMaslini, popularity:3, price:'85',pricePer: 'грн/шт.',id: '11021',typeId: '12'},
        {name:'Маслины "Торедо" 420гр.',img:images.toredoMaslini, popularity:3, price:'56.25',pricePer: 'грн/шт.',id: '11022',typeId: '12'},
        {
            name:'Аджика "Руна" 212гр.',
            img:images.adjicaRuna,
            popularity:3,
            price:'36.75',
            pricePer: 'грн/шт.',
            id: '12001',
            typeId: '13'
        },
        {name:'Горчица крепкая "Гуляй поле"',img:images.gorchicaGulayPole, popularity:3, price:'15.50',pricePer: 'грн/шт.',id: '12002',typeId: '13'},
        {name:'Горчица шайба 100гр.',img:images.gorchicaShaiba, popularity:3, price:'11.90',pricePer: 'грн/шт.',id: '12003',typeId: '13'},
        {name:'Горчица "Торчин"',img:images.gorchicaTorchin, popularity:3, price:'21.25',pricePer: 'грн/шт.',id: '12004',typeId: '13'},
        {name:'Кетчуп детский "Торчин"',img:images.ketchupDetskiy, popularity:3, price:'31.25',pricePer: 'грн/шт.',id: '12005',typeId: '13'},
        {name:'Кетчуп нежный "Торчин"',img:images.ketchupLagidniy, popularity:3, price:'32.50',pricePer: 'грн/шт.',id: '12006',typeId: '13'},
        {name:'Кетчуп к шашлыку "Торчин"',img:images.ketchupShashlik, popularity:3, price:'32.50',pricePer: 'грн/шт.',id: '12007',typeId: '13'},
        {name:'Кетчуп "Умный выбор"',img:images.ketchupRozumnVibor, popularity:3, price:'18.75',pricePer: 'грн/шт.',id: '12008',typeId: '13'},
        {name:'Кетчуп чилли "Торчин"',img:images.ketchupChili, popularity:3, price:'32.50',pricePer: 'грн/шт.',id: '12009',typeId: '13'},
        {name:'Майонез "Кор. Смак" 30% 160гр.',img:images.korSmak30160, popularity:3, price:'13.90',pricePer: 'грн/шт.',id: '12010',typeId: '13'},
        {name:'Майонез "Кор. Смак" 30% 300гр.',img:images.korSmak30300, popularity:3, price:'27.50',pricePer: 'грн/шт.',id: '12011',typeId: '13'},
        {name:'Майонез "Кор. Смак" 40% 360гр.',img:images.korSmak40360, popularity:3, price:'34.80',pricePer: 'грн/шт.',id: '12012',typeId: '13'},
        {name:'Майонез "Кор. Смак" 67% 300гр.',img:images.korSmak67300, popularity:3, price:'40',pricePer: 'грн/шт.',id: '12013',typeId: '13'},
        {name:'Майонез "Кор. Смак" 67% 500гр.',img:images.korSmak67500, popularity:3, price:'68',pricePer: 'грн/шт.',id: '12014',typeId: '13'},
        {name:'Майонез "Оліс" 67% 300гр.',img:images.olis67300, popularity:3, price:'37.50',pricePer: 'грн/шт.',id: '12015',typeId: '13'},
        {name:'Соус соевый "Bonsai" 200мл.',img:images.soeviySousBanzai, popularity:3, price:'22.5',pricePer: 'грн/шт.',id: '12016',typeId: '13'},
        {name:'Аджика зеленая 190гр.',img:images.adzhikaZelenaPlanetaSpecij, popularity:3, price:'50',pricePer: 'грн/шт.',id: '12017',typeId: '13'},
        {name:'Аджика красная 190гр.',img:images.adzhikaChervonaPlanetaSpecij, popularity:3, price:'50',pricePer: 'грн/шт.',id: '12018',typeId: '13'},
        {name:'Соус "Барбекю" 280гр.',img:images.barbekjuPlanetaSpecij, popularity:3, price:'52',pricePer: 'грн/шт.',id: '12019',typeId: '13'},
        {name:'Соус "Сацебели" 280гр.',img:images.sacebeliPlanetaSpecij, popularity:3, price:'52',pricePer: 'грн/шт.',id: '12020',typeId: '13'},
        {name:'Соус "Кари-манго" 280гр.',img:images.sousKariMangoPlanetaSpecij, popularity:3, price:'52',pricePer: 'грн/шт.',id: '12021',typeId: '13'},
        {name:'Соус "Ткемали" 280гр.',img:images.tkemaliPlanetaSpecij, popularity:3, price:'52',pricePer: 'грн/шт.',id: '12022',typeId: '13'},
        {name:'Соус "Чили" 280гр.',img:images.chiliPlanetaSpecij, popularity:3, price:'52',pricePer: 'грн/шт.',id: '12023',typeId: '13'},
        {name:'Соус Мексиканский "Жирнов"',img:images.sousMexi360, popularity:3, price:'31',pricePer: 'грн/шт.',id: '12024',typeId: '13'},
        {name:'Соус Сырный "Жирнов"',img:images.sousSir, popularity:3, price:'31',pricePer: 'грн/шт.',id: '12025',typeId: '13'},
        {name:'Горчица столовая "Жирнов"',img:images.gorchicaGhirnov, popularity:3, price:'22.50',pricePer: 'грн/шт.',id: '12026',typeId: '13'},
        {name:'Кетчуп Нежный "Жирнов" 300гр.',img:images.ketchupLaghGhirnov, popularity:3, price:'22.50',pricePer: 'грн/шт.',id: '12027',typeId: '13'},
        {name:'Кетчуп Шашлычный "Жирнов" 300гр.',img:images.ketchupShashGhirnov, popularity:3, price:'26.50',pricePer: 'грн/шт.',id: '12028',typeId: '13'},
        {name:'Томатная паста "Жирнов" 70гр.',img:images.TomatnayaPasta, popularity:3, price:'14',pricePer: 'грн/шт.',id: '12029',typeId: '13'},
        {name:'Майонез "Жирнов" 60% 500гр.',img:images.majonezZhirnov60, popularity:3, price:'56',pricePer: 'грн/шт.',id: '12030',typeId: '13'},
        {name:'Майонез "Жирнов" 67% 500гр.',img:images.majonezZhirnov67, popularity:3, price:'57.5',pricePer: 'грн/шт.',id: '12031',typeId: '13'},
        {name:'Майонез "Жирнов" 67% 300гр.',img:images.majonezZhirnov67, popularity:3, price:'31.5',pricePer: 'грн/шт.',id: '12032',typeId: '13'},
        {name:'Майонез "Жирнов" 40% 500гр.',img:images.majonezZhirnov40, popularity:3, price:'41.5',pricePer: 'грн/шт.',id: '12033',typeId: '13'},
        {name:'Майонез "Жирнов" 40% 300гр.',img:images.majonezZhirnov40, popularity:3, price:'23.5',pricePer: 'грн/шт.',id: '12034',typeId: '13'},
        {name:'Майонез "Жирнов" 30% 500гр.',img:images.majonezZhirnov30, popularity:3, price:'36.5',pricePer: 'грн/шт.',id: '12035',typeId: '13'},
        {name:'Майонез "Жирнов" 30% 300гр.',img:images.majonezZhirnov30, popularity:3, price:'21.5',pricePer: 'грн/шт.',id: '12036',typeId: '13'},
        {
            name:'Ванилин дешевый',
            img:images.vanilinDesh,
            popularity:3,
            price:'1.25',
            pricePer: 'грн/шт.',
            id: '13001',
            typeId: '14'
        },
        {name:'Ванильный сахар',img:images.vanillaSugar, popularity:3, price:'1.25',pricePer: 'грн/шт.',id: '13002',typeId: '14'},
        {name:'Дрожжи "Градус" 500гр.',img:images.droshiGradus, popularity:3, price:'32,80',pricePer: 'грн/шт.',id: '13003',typeId: '14'},
        {name:'Дрожжи "Змей" 500гр.',img:images.droshiZmei, popularity:3, price:'30,60',pricePer: 'грн/шт.',id: '13004',typeId: '14'},
        {name:'Дрожжи "Львовские" сухие 100гр.',img:images.droshiSyhie, popularity:3, price:'32',pricePer: 'грн/шт.',id: '13005',typeId: '14'},
        {name:'Дрожжи "Pakmaya" сухие',img:images.droshiPakham, popularity:3, price:'32',pricePer: 'грн/шт.',id: '13006',typeId: '14'},
        {name:'Желатин "Мрия"',img:images.shelatinMriya, popularity:3, price:'13,25',pricePer: 'грн/шт.',id: '13007',typeId: '14'},
        {name:'Какао "Мрия"',img:images.kakaoMriya, popularity:3, price:'31,25',pricePer: 'грн/шт.',id: '13008',typeId: '14'},
        {name:'Лимонная кислота 100гр.',img:images.limonKisl, popularity:3, price:'13,75',pricePer: 'грн/шт.',id: '13009',typeId: '14'},
        {name:'Какао',img:images.kakao, popularity:3, price:'370',pricePer: 'грн/кг.',id: '13010',typeId: '14'},
        {name:'Кунжут',img:images.kunzhut, popularity:3, price:'200',pricePer: 'грн/кг.',id: '13011',typeId: '14'},
        {name:'Мак',img:images.mak, popularity:3, price:'180',pricePer: 'грн/кг.',id: '13012',typeId: '14'},
        {
            name:'Изюм',
            img:images.izum,
            popularity:3,
            price:'130',
            pricePer: 'грн/кг.',
            id: '14001',
            typeId: '15'
        },
        {name:'Изюм черный',img:images.izumCherniy, popularity:3, price:'270',pricePer: 'грн/кг.',id: '14002',typeId: '15'},
        {name:'Изюм золотой',img:images.izumZolotoy, popularity:3, price:'320',pricePer: 'грн/кг.',id: '14003',typeId: '15'},
        {name:'Инжир сухой',img:images.inzir230, popularity:3, price:'270',pricePer: 'грн/кг.',id: '14004',typeId: '15'},
        {name:'Банановые чипсы',img:images.bananChipsi, popularity:3, price:'370',pricePer: 'грн/кг.',id: '14005',typeId: '15'},
        {name:'Вишня цукат',img:images.vishnjaSushenaja, popularity:3, price:'370',pricePer: 'грн/кг.',id: '14006',typeId: '15'},
        {name:'Кешью сырой',img:images.keshySiroy, popularity:3, price:'400',pricePer: 'грн/кг.',id: '14007',typeId: '15'},
        {name:'Миндаль',img:images.mindal400, popularity:3, price:'400',pricePer: 'грн/кг.',id: '14008',typeId: '15'},
        {name:'Миндаль',img:images.mindal370, popularity:3, price:'370',pricePer: 'грн/кг.',id: '14009',typeId: '15'},
        {name:'Миндальные хлопья',img:images.mindalHlop, popularity:3, price:'420',pricePer: 'грн/кг.',id: '14010',typeId: '15'},
        {name:'Фисташки',img:images.fistashki, popularity:3, price:'570',pricePer: 'грн/кг.',id: '14011',typeId: '15'},
        {name:'Манго сушеный',img:images.mangoSush, popularity:3, price:'370',pricePer: 'грн/кг.',id: '14012',typeId: '15'},
        {name:'Папайя',img:images.papayaSush, popularity:3, price:'370',pricePer: 'грн/кг.',id: '14013',typeId: '15'},
        {name:'Киви сушеный',img:images.kiviSush, popularity:3, price:'370',pricePer: 'грн/кг.',id: '14014',typeId: '15'},
        {name:'Финик',img:images.finik, popularity:3, price:'150',pricePer: 'грн/кг.',id: '14015',typeId: '15'},
        {name:'Кешью жареный',img:images.keshyShar, popularity:3, price:'420',pricePer: 'грн/кг.',id: '14017',typeId: '15'},
        {name:'Козинаки',img:images.kozinaki, popularity:3, price:'220',pricePer: 'грн/кг.',id: '14018',typeId: '15'},
        {name:'Чернослив сушеный',img:images.chernosliv, popularity:3, price:'140',pricePer: 'грн/кг.',id: '14019',typeId: '15'},
        {name:'Клюква цукат',img:images.klykvaChikat, popularity:3, price:'370',pricePer: 'грн/кг.',id: '14020',typeId: '15'},
        {name:'Помело сушеный',img:images.pomeloCukat, popularity:3, price:'340',pricePer: 'грн/кг.',id: '14021',typeId: '15'},
        {name:'Дыня сушеная',img:images.dinyaCuk, popularity:3, price:'370',pricePer: 'грн/кг.',id: '14022',typeId: '15'},
        {name:'Курага',img:images.kuraga, popularity:3, price:'220',pricePer: 'грн/кг.',id: '14023',typeId: '15'},
        {name:'Лимон цукат',img:images.limonCulat, popularity:3, price:'200',pricePer: 'грн/кг.',id: '14024',typeId: '15'},
        {name:'Мандарин цукат',img:images.mandarinCukat, popularity:3, price:'200',pricePer: 'грн/кг.',id: '14025',typeId: '15'},
        {name:'Лайм цукат',img:images.laimCukat, popularity:3, price:'200',pricePer: 'грн/кг.',id: '14026',typeId: '15'},
        {name:'Семечки тыквы',img:images.semyanaTykvi, popularity:3, price:'220',pricePer: 'грн/кг.',id: '14027',typeId: '15'},
        {name:'Ядро подсолнечника(семечки)',img:images.semechki, popularity:3, price:'100',pricePer: 'грн/кг.',id: '14028',typeId: '15'},
        {name:'Арахис в скорлупе',img:images.arahis, popularity:3, price:'185',pricePer: 'грн/кг.',id: '14029',typeId: '15'},
        {name:'Бразильский орех',img:images.brazilskiyOreh, popularity:3, price:'500',pricePer: 'грн/кг.',id: '14030',typeId: '15'},
        {name:'Миндаль в упаковке',img:images.mindalVUpakov, popularity:3, price:'370',pricePer: 'грн/кг.',id: '14031',typeId: '15'},
        {name:'Фисташки в упаковке',img:images.fistashkiVUpakov, popularity:3, price:'550',pricePer: 'грн/кг.',id: '14032',typeId: '15'},
        {name:'Фундук',img:images.funduk, popularity:3, price:'500',pricePer: 'грн/кг.',id: '14033',typeId: '15'},
        {
            name:'Донаты. 1 упаковка(12шт.)',
            img:images.donuts,
            popularity:3,
            price:'18',
            pricePer: 'грн. за 1 донат',
            id: '15001',
            typeId: '16'
        },
        {name:'Пончики с шоколадной начинкой. 1 упаковка(12шт.)',img:images.ponchiki, popularity:3, price:'168',pricePer: 'грн/шт.',id: '15002',typeId: '16'},
        {name:'Пончик с вишней 1 упаковка(12шт.)',img:images.ponchikVish, popularity:3, price:'168',pricePer: 'грн/шт.',id: '15003',typeId: '16'},
        // {name:'Пончик с клубникой 1 упаковка(12шт.)',img:images.ponchikKlub, popularity:3, price:'145',pricePer: 'грн/шт.',id: '15004',typeId: '16'},
        {name:'Пончик с малиной 1 упаковка(12шт.)',img:images.ponchikMal, popularity:3, price:'168',pricePer: 'грн/шт.',id: '15005',typeId: '16'},
        // {name:'Пончик со згущенкой 1 упаковка(12шт.)',img:images.ponchikSgush, popularity:3, price:'145',pricePer: 'грн/шт.',id: '15006',typeId: '16'},
        {name:'Маффин ванильный с черной смородиной. 1 упаковка(1.5кг.)',img:images.muffinSmor, popularity:3, price:'213',pricePer: 'грн/шт.',id: '15007',typeId: '16'},
        {name:'Маффин ванильный со сгущенкой. 1 упаковка(1.5кг.)',img:images.muffinSgush, popularity:3, price:'204',pricePer: 'грн/шт.',id: '15008',typeId: '16'},
        {name:'Маффин шоколадный с молоком. 1 упаковка(1.5кг.)',img:images.muffinShok, popularity:3, price:'202.5',pricePer: 'грн/шт.',id: '15009',typeId: '16'},
        {name:'Печенье американо кофейное. 1 упаковка(2кг.)',img:images.pechenShok, popularity:3, price:'270',pricePer: 'грн/шт.',id: '15010',typeId: '16'},
        {name:'Печенье американо ванильное. 1 упаковка(2кг.)',img:images.pechenVanil, popularity:3, price:'256',pricePer: 'грн/шт.',id: '15011',typeId: '16'},
        {name:'Печенье Чик-Чик. 1 упаковка(400гр.)',img:images.pechenieChikChik, popularity:3, price:'50',pricePer: 'грн/шт.',id: '15012',typeId: '16'},
        {name:'Рогалики с вишневым повидлом. 1 упаковка(400гр.)',img:images.rogalikiVishna, popularity:3, price:'60',pricePer: 'грн/шт.',id: '15013',typeId: '16'},
        {name:'Пирожное Днепродзержинское. 1 упаковка(15шт.)',img:images.piroshnoe, popularity:3, price:'185',pricePer: 'грн/шт.',id: '15014',typeId: '16'},
        {
            name:'Драники с мясом',
            img:images.dranikiMyasn,
            popularity:3,
            price:'31',
            pricePer: 'грн/шт.',
            id: '16001',
            typeId: '17'
        },
        {name:'Гуляш куриный 100гр.',img:images.gulyashKur, popularity:3, price:'45',pricePer: 'грн/шт.',id: '16002',typeId: '17'},
        {name:'Кабачки фаршированные 100гр.',img:images.kabachkiFarsh, popularity:3, price:'30',pricePer: 'грн/шт.',id: '16003',typeId: '17'},
        {name:'Люля-кебаб',img:images.kebab, popularity:3, price:'41',pricePer: 'грн/шт.',id: '16004',typeId: '17'},
        {name:'Котлета куриная паровая',img:images.kotletaKurParov, popularity:3, price:'39',pricePer: 'грн/шт.',id: '16005',typeId: '17'},
        {name:'Мясо по-французски',img:images.myasoPoFranc, popularity:3, price:'59',pricePer: 'грн/шт.',id: '16006',typeId: '17'},
        {name:'Перец фаршированный 100гр.',img:images.percyFarshirov, popularity:3, price:'30',pricePer: 'грн/шт.',id: '16007',typeId: '17'},
        {name:'Пирожок с капустой',img:images.piroshKapust, popularity:3, price:'25',pricePer: 'грн/шт.',id: '16008',typeId: '17'},
        {name:'Пирожок с мясом',img:images.piroshMyaso, popularity:3, price:'29',pricePer: 'грн/шт.',id: '16009',typeId: '17'},
        {name:'Шницель под сыром',img:images.shnicelPodSirom, popularity:3, price:'51',pricePer: 'грн/шт.',id: '16010',typeId: '17'},
        {name:'Тефтель рыбная',img:images.teftelRibnaya, popularity:3, price:'33',pricePer: 'грн/шт.',id: '16011',typeId: '17'},
        {name:'Котлета в тесте',img:images.kotletaVTeste, popularity:3, price:'32',pricePer: 'грн/шт.',id: '16012',typeId: '17'},
        {name:'Сосиска в тесте',img:images.sosiscaVTeste, popularity:3, price:'32',pricePer: 'грн/шт.',id: '16013',typeId: '17'},
        {name:'Печеночная отбивная',img:images.pechenOtbivnaya, popularity:3, price:'35',pricePer: 'грн/шт.',id: '16014',typeId: '17'},
        {name:'Голубцы 100гр.',img:images.golubci, popularity:3, price:'28',pricePer: 'грн/шт.',id: '16015',typeId: '17'},
        {name:'Печеночная котлета',img:images.pechenKotleta, popularity:3, price:'39',pricePer: 'грн/шт.',id: '16016',typeId: '17'},
        {name:'Шашалык куриный',img:images.shashliKyr, popularity:3, price:'56',pricePer: 'грн/шт.',id: '16017',typeId: '17'},
        {name:'Котлета по-киевски',img:images.kotletaPoKiev, popularity:3, price:'38',pricePer: 'грн/шт.',id: '16018',typeId: '17'},
        {name:'Рулет в куриной коже',img:images.ryletVKyrinoyKoshe, popularity:3, price:'45',pricePer: 'грн/шт.',id: '16019',typeId: '17'},
        {name:'Котлета куриная',img:images.kotletaKyr, popularity:3, price:'39',pricePer: 'грн/шт.',id: '16020',typeId: '17'},
        {name:'Котлета куриная с грибами',img:images.kotletaKyrGribn, popularity:3, price:'39',pricePer: 'грн/шт.',id: '16021',typeId: '17'},
        {name:'Бифштекс с яйцом',img:images.BifshteksSYaytsom, popularity:3, price:'48',pricePer: 'грн/шт.',id: '16022',typeId: '17'},
        {name:'Блины с мясом',img:images.bliniSMyasom, popularity:3, price:'31',pricePer: 'грн/шт.',id: '16023',typeId: '17'},
        {name:'Блины с курицей',img:images.bliniSKyricey, popularity:3, price:'35',pricePer: 'грн/шт.',id: '16024',typeId: '17'},
        {
            name:'Сухой корм для собак "Purina Pro plan" 700гр.',
            img:images.purinaProplan,
            popularity:3,
            price:'180',
            pricePer: 'грн/шт.',
            id: '17001',
            typeId: '18'
        },
        
    ]
}

export const productsReducer = (state = defaultState, action) => {
    switch(action.type){
    case 'CHANGE_TOTAL_PAGES':
        return {...state, totalPages: action.payload}
    case 'CHANGE_SORT_BY':
        return {...state, sortedBy: action.payload}
    case 'CHANGE_PAGE':
        return {...state, page: action.payload}
    default:
        return state
    }
}