import NavigateBar from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Shop from './pages/Shop';
import About from './pages/About';
import {Routes, Route} from "react-router-dom";
import Footer from './components/Footer';
import './components/App.css';
import ProductTypePage from './pages/ProductsTypePage';
import {useSelector} from "react-redux";

function App() {
  const productTypes = useSelector(state => state.productTypes.productTypes);
  return (
    <div className="App">
     <NavigateBar />
     <Routes>
        <Route path='/' element={<Shop />}></Route>
        {/* <Route path='/about' element={<About />}></Route> */}
        {productTypes.map(({url, id, name, title, description}) => 
          <Route key={id} path={url} element={<ProductTypePage title={title} description={description} productType={id} productTypeName={name} /> } exact/>
        )}
        {/* <Route path='/napitki' element={<ProductTypePage />}></Route>
        <Route path='/hlebobulochnie-izdelia' element={<ProductTypePage />}></Route>
        <Route path='/ryba-moreprodukti' element={<ProductTypePage />}></Route>
        <Route path='/kofeynie-produkti' element={<ProductTypePage />}></Route>
        <Route path='/frykti-ovishi' element={<ProductTypePage />}></Route>
        <Route path='/konditerskie-izdelia' element={<ProductTypePage />}></Route>
        <Route path='/molochnie-produkti' element={<ProductTypePage />}></Route> */}
     </Routes>
     <Footer />
    </div>
  );
}

export default App;
