import React, { useContext, useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import {useDispatch, useSelector} from "react-redux";
import classes from "./TypeBar.module.css";
import { useNavigate } from "react-router-dom";


const TypeBar = ({selectedType}) => {
    const productTypes = useSelector(state => state.productTypes);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [dropout, setDropout] = useState(false);
    return(
    <ListGroup className="mt-4 type-bar" >
        <header 
        // onClick={() => setDropout(!dropout)}
        className={classes.ListHeader}>
            <h4>Каталог товаров </h4>
            {/* <div className={!dropout ? classes.Arrow : classes.ActiveArrow }></div>  */}
        </header>
        {productTypes.productTypes.map((type) => 
        <ListGroup.Item 
        key={type.id}
        style={{cursor: "pointer"}} 
        active={selectedType === type.id}
        variant="light"
        onClick={() => {
            dispatch({type:'SET_SELECTED_TYPE', payload:type.id})
            dispatch({type:'CHANGE_PAGE', payload:1})
            navigate(type.url)
            }}>
            {type.name}
        </ListGroup.Item>
       )}
    </ListGroup>
    )
}

export default TypeBar;