const defaultState = {
    selectedType: '0',
    productTypes: [
        {
            name:'Напитки',
            url:'/napitki',
            title:'Сок,квас,газированные напитки - Продукты Днепра Эстрея.Купить Pepsi, Mirinda, 7UP, Borjomi',
            description:'Продукты Днепра Эстрея(Estrella) - Купить Pepsi, Mirinda, 7UP, Borjomi, сок,квас,газированные напитки',
            id: '1'
        },
        {
            name:'Курица, колбасы, свинина',
            url:'/kyrica-svinina-kolbasi',
            title:'Курица, колбасы, свинина, салями - Продукты Днепра Эстрея.Купить грудка, филе, фарш, сосиски',
            description:'Продукты Днепра Эстрея(Estrella) - Купить грудка, филе, фарш, сосиски, курица, колбасы, свинина, салями',
            id: '2'
        },
        {
            name:'Молочные продукты',
            url:'/molochnie-produkti',
            title:'Молочные продукты - Продукты Днепра Эстрея.Купить йогурт, десерт, сливки, молоко, маргарин, Дольче',
            description:'Продукты Днепра Эстрея(Estrella) - Купить йогурт, десерт, сливки, молоко, маргарин, Дольче, молочные продукты',
            id: '3'
        },
        {
            name:'Крупы, каши, мука, соль, сахар',
            url:'/bakaleya',
            title:'Крупы, каши, мука, соль, сахар - Продукты Днепра Эстрея.Купить бакалея, макароны Ярка, спагетти, пшено, рис, гречка, хлопья',
            description:'Продукты Днепра Эстрея(Estrella) - Купить бакалея, макароны Ярка, спагетти, пшено, рис, гречка, хлопья, Крупы, каши, мука, соль, сахар',
            id: '4'
        },
        {
            name:'Фрукты, овощи',
            url:'/frykti-ovishi',
            title:'Фрукты, овощи - Продукты Днепра Эстрея.Купить свекла, морковь, капуста, лук, огурцы, помидоры, грибы, картошка, кабачки, баклажаны',
            description:'Продукты Днепра Эстрея(Estrella) - Купить свекла, морковь, капуста, лук, огурцы, помидоры, грибы, картошка, кабачки, баклажаны, фрукты, овощи',
            id: '5'
        },
        {
            name:'Морепродукты',
            url:'/fish',
            title:'Морепродукты - Продукты Днепра Эстрея.Купить лосось, шпроты, сельдь, мидии',
            description:'Продукты Днепра Эстрея(Estrella) - Купить лосось, шпроты, сельдь, мидии, морепродукты',
            id: '6'
        },
        {
            name:'Зелень',
            url:'/zelen',
            title:'Зелень - Продукты Днепра Эстрея.Купить лук зеленый, петрушка, кинза, мята, укроп, хрен, шпинат, рукола',
            description:'Продукты Днепра Эстрея(Estrella) - Купить лук зеленый, петрушка, кинза, мята, укроп, хрен, шпинат, рукола, зелень',
            id: '7'
        },
        {
            name:'Сладости, чай, кофе, мед',
            url:'/sladosti-kofe',
            title:'Сладости, чай, кофе - Продукты Днепра Эстрея.Купить Nescafe, Jacobs, Cart Noire, шоколад Milka, мед',
            description:'Продукты Днепра Эстрея(Estrella) - Купить Nescafe, Jacobs, Cart Noire, шоколад Milka, сладости, чай, кофе',
            id: '8'
        },
        {
            name:'Масло, уксус',
            url:'/maslo-ysksus',
            title:'Масло, уксус - Продукты Днепра Эстрея.Купить подсолнечное масло, уксус',
            description:'Продукты Днепра Эстрея(Estrella) - Купить подсолнечное масло, уксус',
            id: '9'
        },
        {
            name:'Яйца',
            url:'/yaytsya',
            title:'Яйца - Продукты Днепра Эстрея.Купить яйца С0, С1, XL, яйца с двумя желтками',
            description:'Продукты Днепра Эстрея(Estrella) - Купить яйца С0, С1, XL, яйца с двумя желтками',
            id: '10'
        },
        {
            name:'Специи',
            url:'/specii',
            title:'Специи - Продукты Днепра Эстрея.Купить петрушка сушеная, базилик сушеный, укроп сушеный, горчица, горчичный порошек',
            description:'Продукты Днепра Эстрея(Estrella) - Купить петрушка сушеная, базилик сушеный, укроп сушеный, горчица, горчичный порошек, специи',
            id: '11'
        },
        {
            name:'Консервы',
            url:'/konservi',
            title:'Консервы - Продукты Днепра Эстрея.Купить консервированный горошек, консервированная кукуруза ,Верес, паштет, грибы',
            description:'Продукты Днепра Эстрея(Estrella) - Купить консервированный горошек, консервированная кукуруза ,Верес, паштет, грибы, консервы',
            id: '12'
        },
        {
            name:'Соусы',
            url:'/soysi',
            title:'Соусы - Продукты Днепра Эстрея.Купить кетчуп, майонез, горчица, аджика, Торчин, Королівський смак',
            description:'Продукты Днепра Эстрея(Estrella) - Купить кетчуп, майонез, горчица, аджика, Торчин, Королівський смак, соусы',
            id: '13'
        },
        {
            name:'Продукты для выпечки',
            url:'/vipechka',
            title:'Продукты для выпечки - Продукты Днепра Эстрея.Купить ванилин, дрожжи, желатин, какао, лимонная кислота, Мрия, Львовские, Градус',
            description:'Продукты Днепра Эстрея(Estrella) - Купить ванилин, дрожжи, желатин, какао, лимонная кислота, Мрия, Львовские, Градус, продукты для выпечки',
            id: '14'
        },
        {
            name:'Сухофрукты, орехи, цукаты',
            url:'/suhofrykti-orehi',
            title:'Сухофрукты, орехи, цукаты - Продукты Днепра Эстрея.Купить cухофрукты, изюм, кешью, миндаль, инжир, фисташки',
            description:'Продукты Днепра Эстрея(Estrella) - Купить cухофрукты, изюм, кешью, миндаль, инжир, фисташки, орехи',
            id: '15'
        },
        {
            name:'Выпечка',
            url:'/ponchiki-pechenie',
            title:'Донаты, пончики, маффины - Продукты Днепра Эстрея.Купить выпечку, печенье, пончики, маффины',
            description:'Продукты Днепра Эстрея(Estrella) - Купить выпечку, печенье, пончики, маффины',
            id: '16'
        },
        {
            name:'Кулинария',
            url:'/kulinaria',
            title:'Кулинария, выпечка, котлеты, фаршированные овощи - Продукты Днепра Эстрея.Купить выпечку, котлеты, фаршированные овощи',
            description:'Продукты Днепра Эстрея(Estrella) - Купить выпечку, котлеты, фаршированные овощи',
            id: '17'
        },
        {
            name:'Товары для животных',
            url:'/animals',
            title:'Товары для животных - Продукты Днепра Эстрея.',
            description:'Продукты Днепра Эстрея(Estrella) - Купить товары для животных',
            id: '18'
        },
        
    ]
}

export const productTypesReducer = (state = defaultState, action) => {
    switch(action.type){
    case 'SET_SELECTED_TYPE':
        return {...state, selectedType: action.payload}
    default:
        return state
    }
}