import polana from "./products/drinks/polana.jpg";
import rosa from "./products/drinks/rosa.webp";
import rosa05 from "./products/drinks/rosa0.5.jpg";
import rosa15 from "./products/drinks/rosa1.5.png";
import sok from "./products/drinks/sok.jpg";
import kvasChorniy15 from "./products/drinks/kvasChorniy1.5.jpg";
import kvasBeliy15 from "./products/drinks/kvasBeliy1.5.jpg";
import kvasBeliy05 from "./products/drinks/kvasBeliy0.5.jpg";
import kvasChorniy05 from "./products/drinks/kvasChorniy0.5.jpg";
import sevenUp033 from "./products/drinks/7up0.33.jpg";
import pepsi033 from "./products/drinks/pepsi0.33.jpg";
import mirinda033 from "./products/drinks/mirinda0.33.jpg";
import borjomi1250 from "./products/drinks/borjomi 1.250.jpg";
import borjomi05 from "./products/drinks/borjomi0.5.webp";
import buvette15 from "./products/drinks/buvette.jpg";
import nonStop0250 from "./products/drinks/nonStop0250.jpg";

import grudka from "./products/chiken-sausage-etc/grudka.jpg";
import golen from "./products/chiken-sausage-etc/golen.jpg";
import zheludoc from "./products/chiken-sausage-etc/zheludoc.jpg";
import kolbacaKyr from "./products/chiken-sausage-etc/kolbacaKyr.jpg";
import kolbacaSvin from "./products/chiken-sausage-etc/kolbacaSvin.jpg";
import krilo from "./products/chiken-sausage-etc/krilo.webp";
import broiler from "./products/chiken-sausage-etc/broiler.jpeg";
import lapki from "./products/chiken-sausage-etc/lapki.jpg";
import makotSvin from "./products/chiken-sausage-etc/makotSvin.jpg";
import osheek from "./products/chiken-sausage-etc/osheek.jpg";
import pechenKyr from "./products/chiken-sausage-etc/pechenKyr.jpg";
import pechenSvin from "./products/chiken-sausage-etc/pechenSvin.jpg";
import rebroSvin from "./products/chiken-sausage-etc/rebroSvin.webp";
import serceKyr from "./products/chiken-sausage-etc/serceKyr.jpg";
import fileKyr from "./products/chiken-sausage-etc/fileKyr.jpg";
import fileBedKyr from "./products/chiken-sausage-etc/fileBedKyr.webp";
import fileSKozh from "./products/chiken-sausage-etc/fileSKozh.jpg";
import chetvert from "./products/chiken-sausage-etc/chetvert.png";
import farshKyr from "./products/chiken-sausage-etc/farshKyr.webp";
import kolbacaVar from "./products/chiken-sausage-etc/kolbacaVar.jpg";
import sosiski from "./products/chiken-sausage-etc/sosiskiVishiy.jpg";
import sosiskiPerviy from "./products/chiken-sausage-etc/sosiskiPerviy.jpg";
import klbacaTel from "./products/chiken-sausage-etc/klbacaTel.jpg";
import yshi from "./products/chiken-sausage-etc/Yshi.jpg";
import imperatorskayaSalami from "./products/chiken-sausage-etc/imperatorskayaSalami.jpg";
import kolbacaVarStolichn from "./products/chiken-sausage-etc/kolbacaVarStolichn.jpg";
import praznichnayaKolbas from "./products/chiken-sausage-etc/praznichnayaKolbas.jpg";
import grydinkaSvin from "./products/chiken-sausage-etc/grydinkaSvin.jpg";
import kolbsciKabanoci from "./products/chiken-sausage-etc/kolbsciKabanoci.jpg";
import homeChicken from "./products/chiken-sausage-etc/homeChicken.jpg";

import yogurtFanni from "./products/dairy/yogurtFanni.jpg";
import molokoLactel from "./products/dairy/molokoLactel.jpg";
import margarin250 from "./products/dairy/margarin250.jpg";
import margarin500 from "./products/dairy/margarin500.jpg";
import molokoVolosh from "./products/dairy/molokoVolosh.jpg";
import molokoMolochniyVizit from "./products/dairy/molokoMolochniyVizit.jpg";
import yogurt from "./products/dairy/yogurt.jpg";
import decert from "./products/dairy/decert.jpg";
import slivki from "./products/dairy/slivki.jpg";
import dolceDecertStakan from "./products/dairy/dolceDecertStakan.jpg";
import yogurtAktiviya from "./products/dairy/yogurtAktiviya.jpg";
import yogurtDolceBut from "./products/dairy/yogurtDolceBut.jpg";
import margarinMol500 from "./products/dairy/margarinMol500.jpg";
import margarinOlkomSliv450 from "./products/dairy/margarinOlkomSliv450.jpg";
import masloBelicerkov from "./products/dairy/masloBelicerkov.jpg";
import masloFerma73 from "./products/dairy/masloFerma73.webp";
import masloFerma825 from "./products/dairy/masloFerma825.webp";
import masloFerma73400 from "./products/dairy/masloFerma73400.webp";
import masloGlechik from "./products/dairy/masloGlechik.jpg";
import masloYagotin from "./products/dairy/masloYagotin.webp";
import sirKaniv from "./products/dairy/sirKaniv.jpg";
import slivkiBuronka from "./products/dairy/slivkiBuronka.jpg";

import arnautka from "./products/groats/arnautka.webp";
import bulgur from "./products/groats/bulgur.jpg";
import goroh800 from "./products/groats/goroh800.png";
import grechka from "./products/groats/grechka.jpg";
import grechka2 from "./products/groats/grechka2.jpg";
import kukuruznayaKrupa from "./products/groats/kukuruznayaKrupa.png";
import mannayaKrupa from "./products/groats/mannayaKrupa.jpg";
import perlovka from "./products/groats/perlovka.jpg";
import hlopya from "./products/groats/hlopya.jpg";
import yachka from "./products/groats/yachka.jpg";
import pshenichnayaKrupa from "./products/groats/pshenichnayaKrupa.jpg";
import psheno from "./products/groats/psheno.jpg";
import risKrugliy from "./products/groats/risKrugliy.jpg";
import risPropareniy from "./products/groats/risPropareniy.jpg";
import risovayaSichka from "./products/groats/risovayaSichka.jpg";
import risDlinniy from "./products/groats/risDlinniy.webp";
import spagetti from "./products/groats/spagetti.jpg";
import spagetti450 from "./products/groats/spagetti450.jpg";
import sol from "./products/groats/sol.jpg";
import sahar from "./products/groats/sahar.jpg";
import sahar900 from "./products/groats/sahar900.jpg";
import yarka from "./products/groats/yarka.jpg";
import kievMics from "./products/groats/kievMics.jpg";
import muka from "./products/groats/muka.jpg";
import mukaSon from "./products/groats/mukaSon.jpg";
import muka5Kg from "./products/groats/muka5Kg.jpg";
import bruggenHlopya from "./products/groats/bruggenHlopya.webp";
import yarka1 from "./products/groats/yarka1.jpg";
import ovsanka from "./products/groats/ovsanka.jpg";
import sahar1000 from "./products/groats/sahar1000.webp";

import apelsin from "./products/fruits-vegetables/apelsin.jpg";
import banani from "./products/fruits-vegetables/banani.jpg";
import kartofBelarosa from "./products/fruits-vegetables/kartofBelarosa.jpg";
import buryak from "./products/fruits-vegetables/buryak.jpg";
import kartofGranada from "./products/fruits-vegetables/kartofGranada.jpg";
import kapusta from "./products/fruits-vegetables/kapusta.jpg";
import kartofArizona from "./products/fruits-vegetables/kartofArizona.jpg";
import krasniyPerec from "./products/fruits-vegetables/krasniyPerec.jpg";
import kapustaKrasnokoch from "./products/fruits-vegetables/kapustaKrasnokoch.jpg";
import limon from "./products/fruits-vegetables/limon.jpg";
import lukKrupniy from "./products/fruits-vegetables/lukKrupniy.webp";
import luk from "./products/fruits-vegetables/luk.jpg";
import mandarini from "./products/fruits-vegetables/mandarini.jpg";
import morkov from "./products/fruits-vegetables/morkov.jpg";
import ogurci from "./products/fruits-vegetables/ogurci.jpg";
import kapustaPecin from "./products/fruits-vegetables/kapustaPecin.jpg";
import pomidor from "./products/fruits-vegetables/pomidor.jpg";
import fasol from "./products/fruits-vegetables/fasol.jpeg";
import yablGala from "./products/fruits-vegetables/yablGala.jpg";
import yablGold from "./products/fruits-vegetables/yablGold.jpg";
import yablRedChif from "./products/fruits-vegetables/yablRedChif.jpg";
import kapustaCvetnaya from "./products/fruits-vegetables/kapustaCvetnaya.jpg";
import cesnok from "./products/fruits-vegetables/cesnok.jpg";
import granat from "./products/fruits-vegetables/granat.jpg";
import cykini from "./products/fruits-vegetables/cykini.jpg";
import vishna from "./products/fruits-vegetables/vishna.jpg";
import nektarin from "./products/fruits-vegetables/nektarin.jpg";
import malina from "./products/fruits-vegetables/malina.jpg";
import imbir from "./products/fruits-vegetables/imbir.jpg";
import greypfrut from "./products/fruits-vegetables/greypfrut.jpg";
import cherri from "./products/fruits-vegetables/cherri.jpg";
import ananas from "./products/fruits-vegetables/ananas.jpg";
import mango from "./products/fruits-vegetables/mango.jpg";
import avokado from "./products/fruits-vegetables/avokado.jpg";
import grushi from "./products/fruits-vegetables/grushi.jpg";
import abricos from "./products/fruits-vegetables/abricos.jpg";
import persikInzir from "./products/fruits-vegetables/persikInzir.jpg";
import arbuz from "./products/fruits-vegetables/arbuz.jpg";
import persik from "./products/fruits-vegetables/persik.jpg";
import redis from "./products/fruits-vegetables/redis.jpg";
import laim from "./products/fruits-vegetables/laim.jpg";
import dina from "./products/fruits-vegetables/dina.jpg";
import avokXaac from "./products/fruits-vegetables/avokXaac.jpg";
import perecZhelt from "./products/fruits-vegetables/perecZhelt.jpg";
import slivi from "./products/fruits-vegetables/slivi.jpg";
import kivi from "./products/fruits-vegetables/kivi.jpg";
import kabachki from "./products/fruits-vegetables/kabachki.jpg";
import baklazhani from "./products/fruits-vegetables/baklazhani.jpg";
import klubnika from "./products/fruits-vegetables/klubnika.jpg";
import vinograd from "./products/fruits-vegetables/vinograd.jpg";
import gribiPecher from "./products/fruits-vegetables/gribiPecher.jpg";
import perecOranzh from "./products/fruits-vegetables/perecOranzh.jpg";
import lukMars from "./products/fruits-vegetables/lukMars.jpg";
import batat from "./products/fruits-vegetables/batat.jpg";
import daikon from "./products/fruits-vegetables/daikon.jpg";
import kapusBrokk from "./products/fruits-vegetables/kapusBrokk.jpg";
import eshevika from "./products/fruits-vegetables/eshevika.jpg";
import pomidorKokt from "./products/fruits-vegetables/pomidorKokt.jpg";
import golubica from "./products/fruits-vegetables/golubica.jpg";
import smorodina from "./products/fruits-vegetables/smorodina.jpg";
import perecBeloz from "./products/fruits-vegetables/perecBeloz.jpg";
import ogurecKornishon from "./products/fruits-vegetables/ogurecKornishon.jpg";
import perecKapia from "./products/fruits-vegetables/perecKapia.jpg";
import pomidorSlivka from "./products/fruits-vegetables/pomidorSlivka.jpg";
import chiliKrasn from "./products/fruits-vegetables/chiliKrasn.webp";
import chiliZelen from "./products/fruits-vegetables/chiliZelen.png";
import riviera from "./products/fruits-vegetables/riviera.jpg";
import lukSterling from "./products/fruits-vegetables/lukSterling.jpg";
import pomidorPole from "./products/fruits-vegetables/pomidorPole.jpg";
import lukPorey from "./products/fruits-vegetables/lukPorey.png";
import korenSelder from "./products/fruits-vegetables/korenSelder.webp";
import ogurGlad from "./products/fruits-vegetables/ogurGlad.jpg";
import garlic from "./products/fruits-vegetables/garlic.jpg";
import cabbageEarl from "./products/fruits-vegetables/cabbageEarl.jpg";

import gystera from "./products/fish/gystera.jpg";
import moyva from "./products/fish/moyva.jpg";
import salaka from "./products/fish/salaka.jpg";
import shprot from "./products/fish/shprot.jpg";
import obrLosos from "./products/fish/obrLosos.jpg";
import vomer from "./products/fish/vomer.jpg";
import bryshkiLos from "./products/fish/bryshkiLos.jpg";
import moreproduc from "./products/fish/moreproduc.jpg";
import lososevoeMaslo from "./products/fish/lososevoeMaslo.jpg";
import lososevoyNabor from "./products/fish/lososevoyNabor.jpg";
import lososVMasle from "./products/fish/lososVMasle.jpg";
import myasoMidiy from "./products/fish/myasoMidiy.jpg";
import seldVMasle from "./products/fish/seldVMasle.jpg";
import hek from "./products/fish/hek.jpg";

import aisberg from "./products/greenery/aisberg.jpg";
import bionda from "./products/greenery/bionda.jpg";
import kinza from "./products/greenery/kinza.jpg";
import lykZel from "./products/greenery/lykZel.jpg";
import myata from "./products/greenery/myata.jpg";
import petryshka from "./products/greenery/petryshka.jpg";
import rossa from "./products/greenery/rossa.jpg";
import romen from "./products/greenery/romen.jpg";
import ykrop from "./products/greenery/ykrop.jpg";
import hrenDlaKons from "./products/greenery/hrenDlaKons.jpg";
import hren from "./products/greenery/hren.jpg";
import bazilicKrasn from "./products/greenery/bazilicKrasn.jpg";
import rykola from "./products/greenery/rykola.jpg";
import tarhun from "./products/greenery/tarhun.jpg";
import shpinat from "./products/greenery/shpinat.jpg";
import selderejStebel from "./products/greenery/selderej-stebel.jpg";


import nescTriVOdnom from "./products/sweets-cofe/nescTriVOdnom.jpg";
import marshmCorn from "./products/sweets-cofe/marshm.jpg";
import marshmBarb from "./products/sweets-cofe/marshmellow.jpg";
import nescafeCl from "./products/sweets-cofe/nescafeCl.jpg";
import nsExp from "./products/sweets-cofe/nsExp.jpg";
import nsGold from "./products/sweets-cofe/nsGold.jpg";
import shokolStuden from "./products/sweets-cofe/shokolStuden.jpg";
import shokolSCBlack from "./products/sweets-cofe/shokolSCBlack.jpg";
import shokolSCLate from "./products/sweets-cofe/shokolSCLate.jpg";
import sgushenka from "./products/sweets-cofe/sgushenka.png";
import lovare1001N24 from "./products/sweets-cofe/lovare1001N24.png";
import lovarechampag24 from "./products/sweets-cofe/lovarechampag24.webp";
import loverechampag80gr from "./products/sweets-cofe/loverechampag80gr.webp";
import monomaxEarlGrey24 from "./products/sweets-cofe/monomaxEarlGrey24.webp";
import cartNoire140 from "./products/sweets-cofe/cartNoire140.webp";
import jacobs120 from "./products/sweets-cofe/jacobs120.webp";
import jacobs200 from "./products/sweets-cofe/jacobs200.webp";
import milkaOreo100 from "./products/sweets-cofe/milkaOreo100.jpg";
import milkaOreo300 from "./products/sweets-cofe/milkaOreo300.jpg";
import milkaAlpine270 from "./products/sweets-cofe/milkaAlpine270.jpg";
import ritterSportNuts from "./products/sweets-cofe/ritterSportNuts.jpeg";
import ritterSportCacao from "./products/sweets-cofe/ritterSportCacao.jpg";
import ritterSportBiscuit from "./products/sweets-cofe/ritterSportBiscuit.jpg";
import riccoCoffe from "./products/sweets-cofe/riccoCoffe.webp";
import woogieMalina from "./products/sweets-cofe/woogieMalina.jpg";
import woogieLimonApels from "./products/sweets-cofe/woogieLimonApels.jpg";
import woogieApels from "./products/sweets-cofe/woogieApels.jpg";
import woogieVish from "./products/sweets-cofe/woogieVish.jpg";
import woogieYabl from "./products/sweets-cofe/woogieYabl.jpg";
import woogieYagodi from "./products/sweets-cofe/woogieYagodi.jpg";
import toffix from "./products/sweets-cofe/toffix.png";
import toffixSour from "./products/sweets-cofe/toffixSour.jpg";
import amantiVishnya from "./products/sweets-cofe/amantiVishnya.jpg";
import amantiBaunty from "./products/sweets-cofe/amantiBaunty.jpg";
import amantiKolada from "./products/sweets-cofe/amantiKolada.jpg";
import amantiMango from "./products/sweets-cofe/amantiMango.jpg";
import amantiMohito from "./products/sweets-cofe/amantiMohito.jpg";
import med from "./products/sweets-cofe/med.jpg";
import rastvorimiyKofe from "./products/sweets-cofe/rastvorimiyKofe.jpg";
import chaiKilonistCranberry from "./products/sweets-cofe/chaiKilonistCranberry.jpg";
import chaiKilonistFruits from "./products/sweets-cofe/chaiKilonistFruits.jpg";
import chaiKilonistPekoe from "./products/sweets-cofe/chaiKilonistPekoe.jpg";
import cofeKilonistZerno from "./products/sweets-cofe/cofeKilonistZerno.jpg";
import lovareFestSet from "./products/sweets-cofe/lovareFestSet.png";
import lovarePrimeSet from "./products/sweets-cofe/lovarePrimeSet.jpg";
import monomahSummerTeaSmall from "./products/sweets-cofe/monomahSummerTeaSmall.jpg";
import qualiteaMango from "./products/sweets-cofe/qualiteaMango.jpg";

import yksus from "./products/oil-vinegar/yksus.jpg";
import maslo5 from "./products/oil-vinegar/maslo5.png";
import maslo1 from "./products/oil-vinegar/maslo1.jpg";
import masloDenko from "./products/oil-vinegar/masloDenko.webp";
import masloOlivia from "./products/oil-vinegar/masloOlivia.jpg";

import yaytso2 from "./products/eggs/yaytso2.jpg";
import yaytsoXL from "./products/eggs/yaytsoXL.jpg";
import yaytsoC0 from "./products/eggs/yaytsoC0.jpg";
import yaytsoC1 from "./products/eggs/yaytsoC1.jpg";

import petrushka from "./products/spice/petrushka.jpg";
import bazilik from "./products/spice/bazilik.jpg";
import ykropSysh from "./products/spice/ykrop.jpg";
import gorchicaPorosh from "./products/spice/gorchicaPorosh.jpg";
import gorchitsaZerno from "./products/spice/gorchitsaZerno.webp";
import torchin10Ovoshei from "./products/spice/torchin10Ovoshei.png";

import ananasKolca from "./products/preserves/ananasKolca.jpg";
import ananacKusochki from "./products/preserves/ananacKusochki.webp";
import gorohASP from "./products/preserves/gorohASP.webp";
import gorohBabush from "./products/preserves/gorohBabush.jpg";
import gorohFortuna from "./products/preserves/gorohFortuna.webp";
import gorohShedraNyva from "./products/preserves/gorohShedraNyva.png";
import gorohVeres from "./products/preserves/gorohVeres.jpg";
import kukurudzaVeres from "./products/preserves/kukurudzaVeres.jpg";
import kukuruzaKonservirovannayaBabusin from "./products/preserves/kukuruzaKonservirovannayaBabusin.jpeg";
import pashtetBrit from "./products/preserves/pashtetBrit.jpg";
import pashtetDomashn from "./products/preserves/pashtetDomashn.jpg";
import PashtetMasnoy from "./products/preserves/PashtetMasnoy.jpg";
import pashtetPechon from "./products/preserves/pashtetPechon.jpg";
import pashtetSChernosliv from "./products/preserves/pashtetSChernosliv.jpg";
import bichkiVTomate from "./products/preserves/bichkiVTomate.jpg";
import gribiAsorti from "./products/preserves/gribiAsorti.jpg";
import gribiOpyata from "./products/preserves/gribiOpyata.jpg";
import marinShamp from "./products/preserves/marinShamp.jpg";
import marinShamp2 from "./products/preserves/marinShamp2.jpg";
import marinShamp3 from "./products/preserves/marinShamp3.jpg";
import ibericaMaslini from "./products/preserves/ibericaMaslini.jpg";
import toredoMaslini from "./products/preserves/toredoMaslini.jpg";

import adjicaRuna from "./products/sauce/adjicaRuna.png";
import gorchicaGulayPole from "./products/sauce/gorchicaGulayPole.jpg";
import gorchicaShaiba from "./products/sauce/gorchicaShaiba.jpg";
import gorchicaTorchin from "./products/sauce/gorchicaTorchin.jpg";
import ketchupDetskiy from "./products/sauce/ketchupDetskiy.jpg";
import ketchupLagidniy from "./products/sauce/ketchupLagidniy.jpg";
import ketchupRozumnVibor from "./products/sauce/ketchupRozumnVibor.jpg";
import ketchupShashlik from "./products/sauce/ketchupShashlik.webp";
import ketchupChili from "./products/sauce/ketchupChili.webp";
import korSmak30160 from "./products/sauce/korSmak30160.jpg";
import korSmak30300 from "./products/sauce/korSmak30300.jpg";
import korSmak40360 from "./products/sauce/korSmak40360.jpg";
import korSmak67300 from "./products/sauce/korSmak67300.jpg";
import korSmak67500 from "./products/sauce/korSmak67500.jpg";
import olis67300 from "./products/sauce/olis67300.jpeg";
import soeviySousBanzai from "./products/sauce/soeviySousBanzai.jpg";
import adzhikaZelenaPlanetaSpecij from "./products/sauce/adzhikaZelenaPlanetaSpecij.jpg";
import adzhikaChervonaPlanetaSpecij from "./products/sauce/adzhikaChervonaPlanetaSpecij.jpg";
import barbekjuPlanetaSpecij from "./products/sauce/barbekjuPlanetaSpecij.jpg";
import chiliPlanetaSpecij from "./products/sauce/chiliPlanetaSpecij.jpg";
import sacebeliPlanetaSpecij from "./products/sauce/sacebeliPlanetaSpecij.jpg";
import sousKariMangoPlanetaSpecij from "./products/sauce/sousKariMangoPlanetaSpecij.jpg";
import tkemaliPlanetaSpecij from "./products/sauce/tkemaliPlanetaSpecij.jpg";
import gorchicaGhirnov from "./products/sauce/gorchicaGhirnov.jpg";
import ketchupLaghGhirnov from "./products/sauce/ketchupLaghGhirnov.jpg";
import ketchupShashGhirnov from "./products/sauce/ketchupShashGhirnov.jpg";
import sousSir from "./products/sauce/sousSir.jpg";
import sousMexi360 from "./products/sauce/sousMexi360.jpg";
import TomatnayaPasta from "./products/sauce/TomatnayaPasta.jpg";
import majonezZhirnov30 from "./products/sauce/majonezZhirnov30.png";
import majonezZhirnov40 from "./products/sauce/majonezZhirnov40.png";
import majonezZhirnov60 from "./products/sauce/majonezZhirnov60.png";
import majonezZhirnov67 from "./products/sauce/majonezZhirnov67.png";


import vanilinDesh from "./products/baking/vanilinDesh.webp";
import vanillaSugar from "./products/baking/vanillaSugar.jpg";
import droshiGradus from "./products/baking/droshiGradus.webp";
import droshiPakham from "./products/baking/droshiPakham.jpg";
import droshiSyhie from "./products/baking/droshiSyhie.jpg";
import droshiZmei from "./products/baking/droshiZmei.webp";
import shelatinMriya from "./products/baking/shelatinMriya.jpg";
import kakaoMriya from "./products/baking/kakaoMriya.png";
import limonKisl from "./products/baking/limonKisl.jpg";
import kakao from "./products/baking/kakao.webp";
import kunzhut from "./products/baking/kunzhut.jpg";
import mak from "./products/baking/mak.jpg";


import izum from "./products/dried-fruits/izum.jpg";
import bananChipsi from "./products/dried-fruits/bananChipsi.jpg";
import finik from "./products/dried-fruits/finik.jpg";
import fistashki from "./products/dried-fruits/fistashki.jpg";
import inzir230 from "./products/dried-fruits/inzir230.jpg";
import izumCherniy from "./products/dried-fruits/izumCherniy.jpg";
import izumZolotoy from "./products/dried-fruits/izumZolotoy.jpg";
import keshyShar from "./products/dried-fruits/keshyShar.jpg";
import keshySiroy from "./products/dried-fruits/keshySiroy.jpg";
import kiviSush from "./products/dried-fruits/kiviSush.jpg";
import mangoSush from "./products/dried-fruits/mangoSush.jpg";
import mindal370 from "./products/dried-fruits/mindal370.jpg";
import mindal400 from "./products/dried-fruits/mindal400.jpg";
import mindalHlop from "./products/dried-fruits/mindalHlop.jpg";
import papayaSush from "./products/dried-fruits/papayaSush.jpg";
import vishnjaSushenaja from "./products/dried-fruits/vishnjaSushenaja.jpg";
import chernosliv from "./products/dried-fruits/chernosliv.jpg";
import dinyaCuk from "./products/dried-fruits/dinyaCuk.jpg";
import klykvaChikat from "./products/dried-fruits/klykvaChikat.jpg";
import kozinaki from "./products/dried-fruits/kozinaki.webp";
import kuraga from "./products/dried-fruits/kuraga.jpg";
import limonCulat from "./products/dried-fruits/limonCulat.webp";
import pomeloCukat from "./products/dried-fruits/pomeloCukat.jpg";
import mandarinCukat from "./products/dried-fruits/mandarinCukat.webp";
import laimCukat from "./products/dried-fruits/laimCukat.webp";
import semyanaTykvi from "./products/dried-fruits/semyanaTykvi.jpg";
import semechki from "./products/dried-fruits/semechki.jpg";
import arahis from "./products/dried-fruits/arahis.jpg";
import brazilskiyOreh from "./products/dried-fruits/brazilskiyOreh.jpg";
import fistashkiVUpakov from "./products/dried-fruits/fistashkiVUpakov.jpg";
import funduk from "./products/dried-fruits/funduk.jpg";
import mindalVUpakov from "./products/dried-fruits/mindalVUpakov.jpg";

import muffinSgush from "./products/buns-donuts/muffinSgush.jpg";
import muffinShok from "./products/buns-donuts/muffinShok.jpg";
import muffinSmor from "./products/buns-donuts/muffinSmor.jpg";
// import ponchikAbric from "./products/buns-donuts/ponchikAbric.jpg";
// import ponchikKlub from "./products/buns-donuts/ponchikKlub.jpg";
import ponchikVish from "./products/buns-donuts/ponchikVish.jpg";
import ponchiki from "./products/buns-donuts/ponchiki.jpg";
import donuts from "./products/buns-donuts/donuts.jpg";
import ponchikMal from "./products/buns-donuts/ponchikMal.jpg";
// import ponchikSgush from "./products/buns-donuts/ponchikSgush.jpg";
import pechenVanil from "./products/buns-donuts/pechenVanil.jpg";
import pechenShok from "./products/buns-donuts/pechenShok.jpg";
import pechenieChikChik from "./products/buns-donuts/pechenieChikChik.jpg";
import rogalikiVishna from "./products/buns-donuts/rogalikiVishna.jpg";
import piroshnoe from "./products/buns-donuts/piroshnoe.jpg";

import dranikiMyasn from "./products/canteen/dranikiMyasn.webp";
import gulyashKur from "./products/canteen/gulyashKur.jpg";
import kabachkiFarsh from "./products/canteen/kabachkiFarsh.jpg";
import kebab from "./products/canteen/kebab.jpg";
import kotletaKurParov from "./products/canteen/kotletaKurParov.jpg";
import kotletaVTeste from "./products/canteen/kotletaVTeste.jpg";
import myasoPoFranc from "./products/canteen/myasoPoFranc.jpg";
import percyFarshirov from "./products/canteen/percyFarshirov.jpg";
import piroshKapust from "./products/canteen/piroshKapust.jpg";
import piroshMyaso from "./products/canteen/piroshMyaso.jpg";
import shnicelPodSirom from "./products/canteen/shnicelPodSirom.jpg";
import sosiscaVTeste from "./products/canteen/sosiscaVTeste.jpg";
import teftelRibnaya from "./products/canteen/teftelRibnaya.jpg";
import BifshteksSYaytsom from "./products/canteen/BifshteksSYaytsom.jpg";
import bliniSKyricey from "./products/canteen/bliniSKyricey.jpg";
import bliniSMyasom from "./products/canteen/bliniSMyasom.jpg";
import golubci from "./products/canteen/golubci.jpg";
import kotletaKyr from "./products/canteen/kotletaKyr.jpg";
import kotletaKyrGribn from "./products/canteen/kotletaKyrGribn.jpg";
import kotletaPoKiev from "./products/canteen/kotletaPoKiev.jpg";
import pechenKotleta from "./products/canteen/pechenKotleta.jpg";
import pechenOtbivnaya from "./products/canteen/pechenOtbivnaya.jpg";
import ryletVKyrinoyKoshe from "./products/canteen/ryletVKyrinoyKoshe.jpg";
import shashliKyr from "./products/canteen/shashliKyr.jpg";


import purinaProplan from "./products/animals/purinaProplan.jpg";


export default {
    polana,
    rosa,
    rosa05,
    rosa15,
    sok,
    kvasChorniy15,
    kvasBeliy15,
    kvasBeliy05,
    kvasChorniy05,
    sevenUp033,
    pepsi033,
    mirinda033,
    borjomi1250,
    borjomi05,
    buvette15,
    nonStop0250,

    grudka,
    golen,
    zheludoc,
    kolbacaKyr,
    kolbacaSvin,
    krilo,
    broiler,
    lapki,
    makotSvin,
    osheek,
    pechenKyr,
    pechenSvin,
    rebroSvin,
    serceKyr,
    fileKyr,
    fileBedKyr,
    fileSKozh,
    chetvert,
    farshKyr,
    kolbacaVar,
    sosiski,
    sosiskiPerviy,
    klbacaTel,
    yshi,
    imperatorskayaSalami,
    kolbacaVarStolichn,
    praznichnayaKolbas,
    grydinkaSvin,
    kolbsciKabanoci,
    homeChicken,

    yogurtFanni,
    molokoLactel,
    margarin250,
    margarin500,
    molokoVolosh,
    molokoMolochniyVizit,
    yogurt,
    decert,
    slivki,
    dolceDecertStakan,
    yogurtAktiviya,
    yogurtDolceBut,
    margarinMol500,
    margarinOlkomSliv450,
    masloBelicerkov,
    masloFerma73,
    masloFerma825,
    masloFerma73400,
    masloGlechik,
    masloYagotin,
    sirKaniv,
    slivkiBuronka,

    arnautka,
    bulgur,
    goroh800,
    grechka,
    grechka2,
    kukuruznayaKrupa,
    mannayaKrupa,
    perlovka,
    hlopya,
    yachka,
    pshenichnayaKrupa,
    psheno,
    risKrugliy,
    risPropareniy,
    risovayaSichka,
    risDlinniy,
    spagetti,
    spagetti450,
    sol,
    sahar,
    yarka,
    kievMics,
    muka,
    mukaSon,
    sahar900,
    muka5Kg,
    bruggenHlopya,
    yarka1,
    ovsanka,
    sahar1000,

    apelsin,
    banani,
    kartofBelarosa,
    buryak,
    kartofGranada,
    kapusta,
    kartofArizona,
    krasniyPerec,
    kapustaKrasnokoch,
    limon,
    lukKrupniy,
    luk,
    mandarini,
    morkov,
    ogurci,
    kapustaPecin,
    pomidor,
    fasol,
    yablGala,
    yablGold,
    yablRedChif,
    kapustaCvetnaya,
    cesnok,
    granat,
    cykini,
    vishna,
    nektarin,
    malina,
    imbir,
    greypfrut,
    cherri,
    ananas,
    mango,
    avokado,
    grushi,
    abricos,
    persikInzir,
    arbuz,
    persik,
    redis,
    laim,
    dina,
    avokXaac,
    perecZhelt,
    slivi,
    kivi,
    kabachki,
    baklazhani,
    klubnika,
    vinograd,
    gribiPecher,
    perecOranzh,
    lukMars,
    batat,
    daikon,
    kapusBrokk,
    eshevika,
    pomidorKokt,
    golubica,
    smorodina,
    perecBeloz,
    ogurecKornishon,
    perecKapia,
    pomidorSlivka,
    chiliKrasn,
    chiliZelen,
    riviera,
    lukSterling,
    pomidorPole,
    lukPorey,
    korenSelder,
    ogurGlad,
    garlic,
    cabbageEarl,

    gystera,
    moyva,
    salaka,
    shprot,
    obrLosos,
    vomer,
    bryshkiLos,
    moreproduc,
    lososevoeMaslo,
    lososevoyNabor,
    lososVMasle,
    myasoMidiy,
    seldVMasle,
    hek,

    aisberg,
    bionda,
    kinza,
    lykZel,
    myata,
    petryshka,
    rossa,
    romen,
    ykrop,
    hren,
    hrenDlaKons,
    bazilicKrasn,
    rykola,
    tarhun,
    shpinat,
    selderejStebel,

    nescTriVOdnom,
    marshmCorn,
    marshmBarb,
    nescafeCl,
    nsExp,
    nsGold,
    shokolStuden,
    shokolSCBlack,
    shokolSCLate,
    sgushenka,
    lovare1001N24,
    lovarechampag24,
    loverechampag80gr,
    monomaxEarlGrey24,
    cartNoire140,
    jacobs120,
    jacobs200,
    milkaOreo100,
    milkaOreo300,
    milkaAlpine270,
    ritterSportNuts,
    ritterSportCacao,
    ritterSportBiscuit,
    riccoCoffe,
    woogieMalina,
    woogieLimonApels,
    woogieApels,
    woogieVish,
    woogieYabl,
    woogieYagodi,
    toffix,
    toffixSour,
    amantiVishnya,
    amantiBaunty,
    amantiKolada,
    amantiMango,
    amantiMohito,
    med,
    rastvorimiyKofe,
    chaiKilonistCranberry,
    chaiKilonistFruits,
    chaiKilonistPekoe,
    cofeKilonistZerno,
    lovareFestSet,
    lovarePrimeSet,
    monomahSummerTeaSmall,
    qualiteaMango,

    maslo5,
    yksus,
    maslo1,
    masloDenko,
    masloOlivia,

    yaytso2,
    yaytsoXL,
    yaytsoC0,
    yaytsoC1,

    petrushka,
    bazilik,
    ykropSysh,
    gorchicaPorosh,
    gorchitsaZerno,
    torchin10Ovoshei,

    ananasKolca,
    ananacKusochki,
    gorohASP,
    gorohBabush,
    gorohFortuna,
    gorohShedraNyva,
    gorohVeres,
    kukurudzaVeres,
    kukuruzaKonservirovannayaBabusin,
    pashtetBrit,
    pashtetDomashn,
    PashtetMasnoy,
    pashtetPechon,
    pashtetSChernosliv,
    bichkiVTomate,
    gribiAsorti,
    gribiOpyata,
    marinShamp,
    marinShamp2,
    marinShamp3,
    ibericaMaslini,
    toredoMaslini,

    adjicaRuna,
    gorchicaGulayPole,
    gorchicaShaiba,
    gorchicaTorchin,
    ketchupDetskiy,
    ketchupLagidniy,
    ketchupRozumnVibor,
    ketchupShashlik,
    ketchupChili,
    korSmak30160,
    korSmak30300,
    korSmak40360,
    korSmak67300,
    korSmak67500,
    olis67300,
    soeviySousBanzai,
    adzhikaZelenaPlanetaSpecij,
    adzhikaChervonaPlanetaSpecij,
    barbekjuPlanetaSpecij,
    chiliPlanetaSpecij,
    sacebeliPlanetaSpecij,
    sousKariMangoPlanetaSpecij,
    tkemaliPlanetaSpecij,
    gorchicaGhirnov,
    ketchupLaghGhirnov,
    ketchupShashGhirnov,
    sousSir,
    sousMexi360,
    TomatnayaPasta,
    majonezZhirnov30,
    majonezZhirnov40,
    majonezZhirnov60,
    majonezZhirnov67,

    vanilinDesh,
    vanillaSugar,
    droshiGradus,
    droshiPakham,
    droshiSyhie,
    droshiZmei,
    shelatinMriya,
    kakaoMriya,
    limonKisl,
    kakao,
    kunzhut,
    mak,

    izum,
    bananChipsi,
    finik,
    fistashki,
    inzir230,
    izumCherniy,
    izumZolotoy,
    keshyShar,
    keshySiroy,
    kiviSush,
    mangoSush,
    mindal370,
    mindal400,
    mindalHlop,
    papayaSush,
    vishnjaSushenaja, 
    chernosliv,
    dinyaCuk,
    klykvaChikat,
    kozinaki,
    kuraga,
    limonCulat,
    pomeloCukat,
    mandarinCukat,
    laimCukat,
    semyanaTykvi,
    semechki,
    arahis,
    brazilskiyOreh,
    fistashkiVUpakov,
    funduk,
    mindalVUpakov,

    donuts,
    muffinSgush,
    muffinShok,
    muffinSmor,
    // ponchikAbric,
    // ponchikKlub,
    ponchikVish,
    ponchikMal,
    // ponchikSgush,
    pechenVanil,
    pechenShok,
    ponchiki,
    pechenieChikChik,
    rogalikiVishna,
    piroshnoe,

    dranikiMyasn,
    gulyashKur,
    kabachkiFarsh,
    kebab,
    kotletaKurParov,
    kotletaVTeste,
    myasoPoFranc,
    percyFarshirov,
    piroshKapust,
    piroshMyaso,
    shnicelPodSirom,
    sosiscaVTeste,
    teftelRibnaya,
    BifshteksSYaytsom,
    bliniSKyricey,
    bliniSMyasom,
    golubci,
    kotletaKyr,
    kotletaKyrGribn,
    kotletaPoKiev,
    pechenKotleta,
    pechenOtbivnaya,
    ryletVKyrinoyKoshe,
    shashliKyr,

    purinaProplan,
    
}