import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch, useSelector } from "react-redux";
import './DropdownSort.css'
import Search from "./utils/search";

function DropdownSort({productsTypeName, setSearch, searchState}) {
    const products = useSelector(state => state.products);
    const dispatch = useDispatch();
    let sortTitle = '';
    switch(products.sortedBy){
        case 'byNameAZ':
            sortTitle = 'Названию: А-Я';break;
        case 'byNameZA':
            sortTitle = 'Названию: Я-А';break;
        case 'byPriseLower':
            sortTitle = 'Цене: От высокой';break;
        case 'byPriseIncrease':
            sortTitle = 'Цене: От низкой';break;
        case 'byPopularity':
            sortTitle = 'По популярности';break;
    }
  return (
    <div className="dropdown-sort  ">
      <div className="dropdownProductsType">
      <span style={{marginLeft: '15px'}}>{productsTypeName}</span>
      <br></br>
      <Search searchState={searchState} setSearch={setSearch} />
      </div>
    <DropdownButton  id="dropdown-item-button" title={sortTitle}>
      <Dropdown.Item onClick={(e) => dispatch({type:'CHANGE_SORT_BY', payload:e.target.value})} value='byPopularity' as="button">По популярности</Dropdown.Item>
      <Dropdown.Item onClick={(e) => dispatch({type:'CHANGE_SORT_BY', payload:e.target.value})} value='byNameAZ' as="button">Названию: А-Я</Dropdown.Item>
      <Dropdown.Item onClick={(e) => dispatch({type:'CHANGE_SORT_BY', payload:e.target.value})} value='byNameZA' as="button">Названию: Я-А</Dropdown.Item>
      <Dropdown.Item onClick={(e) => dispatch({type:'CHANGE_SORT_BY', payload:e.target.value})} value='byPriseLower' as="button">Цене: От высокой к низкой</Dropdown.Item>
      <Dropdown.Item onClick={(e) => dispatch({type:'CHANGE_SORT_BY', payload:e.target.value})} value='byPriseIncrease' as="button">Цене: От низкой к высокой</Dropdown.Item>
    </DropdownButton>
    </div>
  );
}

export default DropdownSort;