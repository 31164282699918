import React, {useState} from "react";
import { Card, Col, Button} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import  './ProductItem.css'
import MyModal from "./UI/MyModal";

const ProductItem = ({product}) => {
    const [modalShow, setModalShow] = React.useState(false);
    return(
    <Col md={3} className="mt-3">
        <Card className="card-item">
        <Card.Img variant="top" src={product.img} />
        <Card.Body className="item-body">
            <Card.Title className=" product-name" >{product.name}</Card.Title>
            <hr className="separating-line" />
            <Card.Text >
            <strong>Цена: </strong> {product.price} {product.pricePer}
            </Card.Text>
            <Button onClick={() => setModalShow(true)} className="card-button">Подробности</Button>
            <MyModal 
            show={modalShow}
            onHide={() => setModalShow(false)} 
            product={product}
            />
        </Card.Body>
        </Card>
    </Col>
    )
}

export default ProductItem;