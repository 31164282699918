import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

const Pages = () => {
    const products = useSelector(state => state.products);
    // const [pages, setPages] = useState([]);
    const dispatch = useDispatch();
    // const totalCount = products.products.length;
    let pageCount = products.totalPages;
    const paginationLimit = [10,20,30,40,50,60,70]
    let pages = [[],[],[],[],[],[],[]];
    let pagesCount = 0;
    for(let i = 1; i < pageCount + 1; i++){
        if(paginationLimit.includes(i)){
            pagesCount++;
        }
        pages[pagesCount].push(i)
    }
    return(
        <div>
           { pages.map((pagesCounts) =>
           pagesCounts.length !== 0 
            ? 
            <Pagination key={pagesCounts[0]}  className="d-flex justify-content-center">
              { pagesCounts.map((page) => 
              <Pagination.Item 
                key={page} 
                active={products.page === page}
                onClick={() => dispatch({type:'CHANGE_PAGE', payload:page})}
                >
                {page}
                </Pagination.Item>
                )
                }
                </Pagination>
                : ''
                )
            }
        </div>
    )
}

export default Pages;