import React from "react";
import TypeBar from "../components/TypeBar";
import { Container, Row, Col } from "react-bootstrap";
import Pages from "../components/Pages";
import ProductList from "../components/ProductList";
import DropdownSort from "../components/DropdownSort";
import {useDispatch} from "react-redux";
import { useState } from "react";
import {Helmet} from "react-helmet";

const ProductTypePage = ({title, description, productType, productTypeName}) => {
    const [searchState, setSearch] = useState('');
    return (
        <Container>
             <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Row className="mt-2">
                <Col md={3}>
                    <TypeBar selectedType={productType}/>
                </Col>
                <Col md={9}>
                    <hr />
                    <DropdownSort searchState={searchState} setSearch={setSearch} productsTypeName={productTypeName}/>
                    <hr />
                    <ProductList searchState={searchState} productSelectedType={productType}  />
                    <Pages />
                </Col>
            </Row>
        </Container>
    )
}

export default ProductTypePage;