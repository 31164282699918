import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import ProductItem from "./ProductItem";
import {useDispatch, useSelector} from "react-redux";
import { sorter } from "./utils/sorter";

const ProductList = ({productSelectedType, searchState}) => {
    const dispatch = useDispatch();
//    const productSelectedType = useSelector(state => state.productTypes.selectedType);
   const products = useSelector(state => state.products);
   let PaginatedProducts = [];
   let selectedTypeProducts =[];
   let pageCount = 0;
//    const scrollHandler = (e) => {
//     console.log('scrollHeight', e.target.documentElement.scrollHeight);
//     console.log('scrollTop', e.target.documentElement.scrollTop);
//     console.log('innerHeight', window.innerHeight);
//    }

   if(productSelectedType !== '0'){
    selectedTypeProducts = products.products.slice();
    selectedTypeProducts = selectedTypeProducts.filter((product) => product.typeId === productSelectedType)
   } else {
    selectedTypeProducts = products.products.slice();
   }
   if(searchState !== ''){
            selectedTypeProducts = selectedTypeProducts.filter((product) => product.name.toLowerCase().includes(searchState));
    }


    pageCount = Math.ceil(selectedTypeProducts.length / products.limit);
    selectedTypeProducts = sorter(selectedTypeProducts, products.sortedBy);
    
    for(let i = 0; i < pageCount; i++){
        PaginatedProducts[i] = selectedTypeProducts.splice(0, products.limit);
    }
    // useEffect(() => {
    //     document.addEventListener('scroll', scrollHandler);
    //     return function (){
    //         document.removeEventListener('scroll', scrollHandler)
    //     };
    // }, [])
    useEffect(() => {
        dispatch({type:'CHANGE_TOTAL_PAGES', payload:pageCount})
    }, [pageCount])
   return(
        <Row className="d-flex mb-5"> 
        {PaginatedProducts.length ?
            PaginatedProducts[products.page - 1].map((product) => 
                <ProductItem key={product.id}  product={product}/>
            )
        :
           <h1 style={{color:'white'}}>
                Такого товара в данной категории нет!
           </h1> 
        }
        </Row>
    )
}

export default ProductList;