import React from "react";
import { Container, Col, Row } from "react-bootstrap";

const Footer = () => {
    return(
        <footer className="bg-dark py-3 footer" style={{color:'#fff', marginTop: '150px'}}>
        <Container>
          <Row>
            <Col md={6}>
              <p className="text-center text-md-left mb-0">
              <strong>Эстрея(Estrella)</strong> - мы продаем и доставляем продовольственные товары в Днепре.
              </p>
            </Col>
            <Col md={6}>
              <ul className="list-inline text-center text-md-right mb-0">
                <li className="list-inline-item">
                  <p>Контакты:</p>
                </li>
                <li className="list-inline-item">
                  <p>066-87-77-005</p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    )
}

export default Footer;