import React from "react";
import { useSelector} from "react-redux";

const Search = ({setSearch, searchState}) => {
    // const products = useSelector(state => state.products.products);
    return(
            <div className="form">
                <form className="search_form">
                <img src="https://cdn-icons-png.flaticon.com/512/711/711319.png"  className="search_img"/>
                    <input 
                    type="text"
                    placeholder="Поиск продукта"
                    onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
                    className="search_input"
                    />
                    {/* <ul className="autocomplete">
                        { searchState ?
                        products.map((product) =>{ 
                            return <li key={product.id} className="autocomplete_item">{product.name}</li>}
                        )
                        : null
                        }
                    </ul> */}
                    
                </form>
            </div>
    )
}

export default Search;