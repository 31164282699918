import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../ProductItem.css"

function MyModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="contained-modal-title-vcenter">
          {props.product.name}
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <Row>
            <Col md={4} className="my-modal-image" style={{margin: "auto"}} >
                <Image className="mr-3 modal-item"  src={props.product.img} rounded/>
            </Col>
            <Col md={6} className="my-modal-info">
                <h4>{props.product.name}</h4>
                <div className="modal-text">
                <p>По поводу заказа обращаться:</p>
                <p><strong>Мобильный номер:</strong> 066-87-77-005</p>
                <p><strong>Viber:</strong> 066-87-77-005</p>
                <p><strong>Email:</strong> estrelladnepr@gmail.com</p>
                </div>
                <div className="modal-price" >
                  <p ><strong>Цена:</strong> {props.product.price} {props.product.pricePer}</p>
                </div>
            </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <Col md={8}>
          <div style={{fontSize: '1.6rem'}}>
            <p ><strong>Цена:</strong> {props.product.price} грн.</p>
          </div>
        </Col>
      </Modal.Footer> */}
    </Modal>
  );
}

export default MyModal;