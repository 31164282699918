import {createStore, combineReducers} from "redux";
import { productsReducer } from "./productsReducer";
import { productTypesReducer } from "./productTypesReducer";

const rootReducer = combineReducers({
    products: productsReducer,
    productTypes: productTypesReducer
})


export const store = createStore(rootReducer);