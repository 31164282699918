import React from "react";
import {Navbar, Container, Nav, Button} from "react-bootstrap";

const NavigateBar = () => {
    return(
        <Navbar bg="success" variant="dark" >
        <Container>
          <Navbar.Brand ><a href="/" className="brand">Эстрея</a></Navbar.Brand>
        <Navbar.Collapse className="justify-content-center">
          <Navbar.Text >
             Продукты по доступной цене в Днепре
          </Navbar.Text>
        </Navbar.Collapse>
          <Navbar.Toggle />
        {/* <Navbar.Collapse className="justify-content-end">
        <Button variant="outline-light">Про нас</Button>
        </Navbar.Collapse> */}
        </Container>
      </Navbar>
    )
}

export default NavigateBar;