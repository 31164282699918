export const sorter = (products, sortBy) => {
    switch(sortBy){
        case 'byNameAZ':
            return products.sort((a,b) => a.name.localeCompare(b.name))
        case 'byNameZA':
            return products.sort((a,b) => a.name.localeCompare(b.name)).reverse()
        case 'byPriseLower':
            return products.sort((a,b) => a.price - b.price).reverse()
        case 'byPriseIncrease':
            return products.sort((a,b) => a.price - b.price)
        case 'byPopularity':
            return products.sort((a,b) => a.popularity - b.popularity)
    }
}